import { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  Card,
  message,
  Upload,
  Typography,
  InputNumber,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload/interface";
import languages from "../../constants/languages";
import { useGetOneAboutUs } from "../../graphql/queries/About-us/findAboutUs";
import {
  AboutUsTranslation,
  AboutUsUpdateInput,
  LanguageCode,
} from "../../types/about-us/aboutUsTypes";
import { useUpdateAboutUs } from "../../graphql/mutations/About-us/updateAboutUs";

const { Text } = Typography;

const EditAboutUs = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data } = useGetOneAboutUs(id!);
  const { updateAboutUs, loading: updating } = useUpdateAboutUs(() =>
    navigate("/about-us")
  );
  const [form] = Form.useForm();
  const [translationMap, setTranslationMap] = useState<
    Record<LanguageCode, AboutUsTranslation | null>
  >({
    KA: null,
    EN: null,
    RU: null,
  });
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [images, setImages] = useState<Record<LanguageCode, string>>({
    KA:
      data?.aboutUs.translations.find((t) => t.language === "KA")?.mainImage ||
      "",
    EN:
      data?.aboutUs.translations.find((t) => t.language === "EN")?.mainImage ||
      "",
    RU:
      data?.aboutUs.translations.find((t) => t.language === "RU")?.mainImage ||
      "",
  });

  useEffect(() => {
    if (data) {
      const map: Record<LanguageCode, AboutUsTranslation | null> = {
        EN: null,
        RU: null,
        KA: null,
      };

      data.aboutUs.translations.forEach((translation) => {
        const languageCode = translation.language as LanguageCode;
        map[languageCode] = translation;
      });

      setTranslationMap(map);

      const imageMap: Record<LanguageCode, string> = {
        KA:
          data.aboutUs.translations.find((t) => t.language === "KA")
            ?.mainImage || "",
        EN:
          data.aboutUs.translations.find((t) => t.language === "EN")
            ?.mainImage || "",
        RU:
          data.aboutUs.translations.find((t) => t.language === "RU")
            ?.mainImage || "",
      };

      setImages(imageMap);
      setUploadedImages(data.aboutUs.imageUrl);

      const formValues: any = {
        translations: data.aboutUs.translations.reduce(
          (acc: any, translation) => {
            acc[translation.language as LanguageCode] = {
              title: translation.title,
              description: translation.description,
            };
            return acc;
          },
          {}
        ),
      };
      formValues.firstYear = Number(data.aboutUs.firstYear);
      formValues.lastYear = Number(data.aboutUs.lastYear);

      if (data.aboutUs.existenceYear) {
        formValues.existenceYear = Number(data.aboutUs.existenceYear);
        formValues.user = Number(data.aboutUs.user);
        formValues.magazine = Number(data.aboutUs.magazine);
      }

      form.setFieldsValue(formValues);
    }
  }, [data, form]);

  const maxImages = data?.aboutUs.imageUrl.length === 6 ? 6 : 4;

  const handleUpload = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const newImageUrl = info.file.response.url;
      setUploadedImages((prev) => [...prev, newImageUrl]);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadMainImage =
    (language: LanguageCode) => (info: UploadChangeParam) => {
      if (info.file.status === "done") {
        const uploadedUrl = info.file.response.url;

        setImages((prev) => ({
          ...prev,
          [language]: uploadedUrl,
        }));

        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

  const handleRemove = (index: number) => {
    setUploadedImages((prev) => prev.filter((_, i) => i !== index));
  };

  const onFinish = async (values: any) => {
    if (uploadedImages.length !== maxImages) {
      message.error(`გთხოვთ ატვირთოთ ${maxImages} სურათი.`);
      return;
    }
    const aboutUsData: AboutUsUpdateInput = {
      imageUrl: { set: uploadedImages },
      firstYear: { set: Number(values.firstYear) },
      lastYear: { set: Number(values.lastYear) },

      translations: {
        update: languages
          .map((language) => {
            const translation = translationMap[language.code as LanguageCode];
            if (!translation) {
              console.error(
                `No translation found for language code: ${language.code}`
              );
              return null;
            }
            const mainImage = images[language.code as LanguageCode];
            const title = values.translations[language.code]?.title || "";
            const description =
              values.translations[language.code]?.description || "";
            if (!title && !description) return null;
            return {
              where: { id: translation.id },
              data: {
                title: { set: title },
                description: { set: description },
                mainImage: { set: mainImage },
              },
            };
          })
          .filter(Boolean) as any,
      },

      magazine: values.magazine ? { set: Number(values.magazine) } : undefined,
      user: values.user ? { set: Number(values.user) } : undefined,
      existenceYear: values.existenceYear
        ? { set: Number(values.existenceYear) }
        : undefined,
    };

    try {
      const { data } = await updateAboutUs({
        variables: { id: id || "", data: aboutUsData },
      });
      if (data) {
        message.success("About Us updated successfully");
        navigate("/about-us");
      } else {
        throw new Error("About Us update failed");
      }
    } catch (err) {
      message.error(
        "An error occurred: " +
          (err instanceof Error ? err.message : String(err))
      );
    }
  };

  return (
    <Fragment>
      <Card title="კომპანიის შესახებ ინფორმაციის რედაქტირება">
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="images">
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={handleUpload}
              multiple
              disabled={maxImages === uploadedImages.length}
              showUploadList={false}
            >
              <Button
                style={{
                  backgroundColor:
                    maxImages === uploadedImages.length ? "#d9d9d9" : "#1890ff",
                  color:
                    maxImages === uploadedImages.length ? "#bfbfbf" : "#fff",
                  borderColor:
                    maxImages === uploadedImages.length ? "#d9d9d9" : "#1890ff",
                  cursor:
                    maxImages === uploadedImages.length
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                სურათის ატვირთვა
              </Button>
            </Upload>
          </Form.Item>
          <Text
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              margin: "8px 0",
            }}
          >
            {`ატვირთეთ მხოლოდ ${maxImages}`}
          </Text>
          {uploadedImages.length > 0 && (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              {uploadedImages.map((url, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "100%",
                    border: "1px solid #d9d9d9",
                    borderRadius: 8,
                    overflow: "hidden",
                    padding: "8px 0 0 8px",
                    height: "66px",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${url}`}
                    alt={`image-${index}`}
                    style={{
                      width: "47px",
                      height: "auto",
                      objectFit: "cover",
                      display: "block",
                    }}
                  />
                  <div
                    onClick={() => handleRemove(index)}
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: "rgba(255,255,255,0.7)",
                      borderRadius: "50%",
                      width: 24,
                      height: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <DeleteOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {languages.map((language) => (
            <Fragment key={language.code}>
              <Divider>{language.name}</Divider>
              {data?.aboutUs.magazine && (
                <Form.Item
                  name={["translations", language.code, "mainImage"]}
                  label="მთავარი სურათი"
                >
                  <Upload
                    action={`${process.env.REACT_APP_API_URL}/upload`}
                    showUploadList={false}
                    onChange={uploadMainImage(language.code as LanguageCode)}
                    style={{ position: "absolute", bottom: 8, left: 8 }}
                  >
                    <Button type="primary" size="small">
                      შეცვალეთ სურათი
                    </Button>
                  </Upload>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      border: "1px solid #d9d9d9",
                      borderRadius: 8,
                      overflow: "hidden",
                      padding: "8px 0 0 8px",
                      height: "66px",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/${
                        images[language.code as LanguageCode] ||
                        "default-image.png"
                      }`}
                      alt="Main"
                      style={{
                        width: "47px",
                        height: "auto",
                        objectFit: "cover",
                        display: "block",
                      }}
                    />
                  </div>
                </Form.Item>
              )}
              <Form.Item
                name={["translations", language.code, "title"]}
                label="სათაური"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ სათაური შეიყვანოთ სათაური",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={["translations", language.code, "description"]}
                label="აღწერა"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ სათაური შეიყვანოთ აღწერა",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Fragment>
          ))}
          <Form.Item
            label="პირველი წელი"
            name="firstYear"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეიყვანოთ კომპანიის არსებობის წელი",
              },
              {
                type: "number",
                message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
              },
            ]}
          >
            <InputNumber
              placeholder="არსებობის წელი"
              style={{ width: "100%" }}
            />
          </Form.Item>{" "}
          <Form.Item
            label="ბოლო წელი"
            name="lastYear"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეიყვანოთ კომპანიის არსებობის წელი",
              },
              {
                type: "number",
                message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
              },
            ]}
          >
            <InputNumber
              placeholder="არსებობის წელი"
              style={{ width: "100%" }}
            />
          </Form.Item>
          {data?.aboutUs.magazine && (
            <div style={{ display: "flex", gap: "20px" }}>
              <Form.Item
                label="არსებობის წელი"
                name="existenceYear"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ კომპანიის არსებობის წელი",
                  },
                  {
                    type: "number",
                    message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                  },
                ]}
              >
                <InputNumber
                  placeholder="არსებობის წელი"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label="მომხმარებლების რაოდენობა"
                name="user"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ მომხმარებლების რაოდენობა",
                  },
                  {
                    type: "number",
                    message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                  },
                ]}
              >
                <InputNumber
                  placeholder="მომხმარებლების რაოდენობა"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label="მაღაზია/ფილიალი"
                name="magazine"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ მაღაზია/ფილიალი",
                  },
                  {
                    type: "number",
                    message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                  },
                ]}
              >
                <InputNumber
                  placeholder="მაღაზია/ფილიალი"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "20px", backgroundColor: "#1890ff" }}
              loading={updating}
            >
              შენახვა
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Fragment>
  );
};

export default EditAboutUs;
