import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
  CreateOneGalleryVideoResponse,
  GalleryVideoCreateInput,
} from "../../../../types/gallery/videoTypes";

const CREATE_ONE_GALLERY_VIDEO = gql`
  mutation CreateOneGalleryVideo($data: GalleryVideoCreateInput!) {
    createOneGalleryVideo(data: $data) {
      id
      url
      thumbnailUrl
      createdAt
      updatedAt
    }
  }
`;

export const useCreateGalleryVideo = (refetchVideos: () => void) => {
  const [createGalleryVideo, { loading, error, data }] = useMutation<
    CreateOneGalleryVideoResponse,
    { data: GalleryVideoCreateInput }
  >(CREATE_ONE_GALLERY_VIDEO, {
    onCompleted: () => {
      refetchVideos();
    },
  });

  return { createGalleryVideo, loading, error, data };
};
