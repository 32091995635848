import { useGetProducts } from "../../../graphql/queries/Products/Products/getProducts";
import { useDeleteProductMutation } from "../../../graphql/mutations/Product/Product/deleteProduct";
import {
  Typography,
  Spin,
  Alert,
  Button,
  Table,
  Popconfirm,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { ApolloError } from "@apollo/client";

const { Title } = Typography;

const ProductListComponent = () => {
  const { products, loading, error, refetch } = useGetProducts();
  const { handleDeleteProduct } = useDeleteProductMutation(refetch);

  if (loading) return <Spin size="large" />;
  if (error) return <Alert message={`Error: ${error.message}`} type="error" />;

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title: "სურათი",
          key: "image",
          render: (_, record: any) => (
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {record.mainImageUrl ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${record.mainImageUrl}`}
                  alt="Image"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.currentTarget.src = "/path/to/fallback/image.jpg";
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Image
                </div>
              )}
            </div>
          ),
        },
        {
          title: "სათაური",
          key: "title",
          render: (_, record: any) => (
            <div className="flex flex-col gap-2">
              <p>{record.translations[0].name}</p>
            </div>
          ),
        },
        {
          title: "ქმედება",
          key: "action",
          render: (_: any, record: any) => (
            <Button.Group>
              <Link to={`/product/edit/${record.id}`}>
                <Button type="primary">რედაქტირება</Button>
              </Link>
              <Popconfirm
                title="გსურთ წაშლა?"
                onConfirm={async () => {
                  const { success, error } = await handleDeleteProduct(
                    record.id
                  );
                  if (success) {
                    message.success("სიახლე წაიშალა");
                  } else {
                    if (error instanceof ApolloError) {
                      message.error(`შეცდომა წაშლისას: ${error.message}`);
                    } else {
                      message.error("შეცდომა წაშლისას: უცნობი შეცდომა");
                    }
                  }
                }}
                okText="კი"
                cancelText="არა"
              >
                <Button type="primary" danger>
                  წაშლა
                </Button>
              </Popconfirm>
            </Button.Group>
          ),
        },
      ]}
      dataSource={products}
      loading={loading}
      title={() => (
        <div>
          <div className="BlogTitle-addButton">
            <Title level={3}>პროდუქტი</Title>
            <Link to={"/product/create"}>
              <Button type="primary">დამატება</Button>
            </Link>
          </div>
        </div>
      )}
      tableLayout="fixed"
      bordered
    />
  );
};

export default ProductListComponent;
