import { gql, useQuery } from "@apollo/client";

export const FIND_GALLERY_IMAGE = gql`
  query GetGalleryImage($id: String!) {
    galleryImage(where: { id: $id }) {
      id
      url
      mobileIndex
      desktopIndex
      createdAt
      updatedAt
    }
  }
`;

export const useGetOneGalleryImage = (id: string | any) => {
  const { data, loading, error } = useQuery(FIND_GALLERY_IMAGE, {
    variables: { id },
    skip: !id,
  });

  return {
    data,
    loading,
    error,
  };
};
