import { useNavigate, useParams } from "react-router-dom";
import { useFindPackage } from "../../../../graphql/queries/Filter/Packages/findPackage";
import { Button, Form, Input, message } from "antd";
import { useUpdatePackage } from "../../../../graphql/mutations/Filter/Packages/editSpecies";
import { useEffect } from "react";
import { ProductTranslationData } from "../../../../types/product/productTypes";

const EditPackage = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { data, refetch } = useFindPackage(id!);
  const { updatePackages } = useUpdatePackage(refetch);
  const navigation = useNavigate();

  useEffect(() => {
    if (data && data.translations) {
      const initialValues = data.translations.reduce(
        (acc: Record<string, string>, translation: ProductTranslationData) => {
          acc[translation.language.toLowerCase()] = translation.name;
          return acc;
        },
        {}
      );
      form.setFieldsValue(initialValues);
    }
  }, [data, form]);

  const onFinish = async (values: { ka: string; en: string; ru: string }) => {
    try {
      await updatePackages({
        variables: {
          data: {
            translations: {
              updateMany: [
                {
                  where: { language: { equals: "KA" } },
                  data: { name: { set: values.ka } },
                },
                {
                  where: { language: { equals: "EN" } },
                  data: { name: { set: values.en } },
                },
                {
                  where: { language: { equals: "RU" } },
                  data: { name: { set: values.ru } },
                },
              ],
            },
          },
          where: {
            id: id,
          },
        },
      });
      navigation("/packages");
      message.success("პროდუქტის სახეობა წარმატებით განახლდა");
    } catch (error) {
      message.error("პროდუქტის სახეობის განახლება ვერ მოხერხდა");
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: "30px" }}
    >
      <Form.Item
        label="სახელი (ქართული)"
        name="ka"
        rules={[
          { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
        ]}
      >
        <Input placeholder="სახელი" />
      </Form.Item>
      <Form.Item
        label="სახელი (ინგლისური)"
        name="en"
        rules={[
          { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
        ]}
      >
        <Input placeholder="სახელი" />
      </Form.Item>
      <Form.Item
        label="სახელი (რუსული)"
        name="ru"
        rules={[{ required: true, message: "გთხოვთ სახელი შეიყვანოთ რუსულად" }]}
      >
        <Input placeholder="სახელი" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          განახლება
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditPackage;
