import { gql, useMutation } from "@apollo/client";

export const DELETE_SPECIES = gql`
  mutation DeleteOneSpecies($id: String!) {
    deleteOneSpecies(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteSpecies = (onCompleted?: () => void) => {
  const [deleteSpecies, { data, loading, error }] = useMutation(
    DELETE_SPECIES,
    {
      onCompleted,
    }
  );

  const handleDeleteSpecies = async (id: string) => {
    try {
      const result = await deleteSpecies({ variables: { id } });
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeleteSpecies,
    data,
    loading,
    error,
  };
};
