import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
  AboutUsCreateInput,
  CreateOneAboutUsResponse,
} from "../../../types/about-us/aboutUsTypes";

const CREATE_ONE_ABOUT_US = gql`
  mutation CreateOneAboutUs($data: AboutUsCreateInput!) {
    createOneAboutUs(data: $data) {
      id
      createdAt
      magazine
      user
      existenceYear
      firstYear
      lastYear
      imageUrl
      translations {
        id
        language
        title
        description
        mainImage
      }
    }
  }
`;

export const useCreateAboutUs = (refetchAboutUs: () => void) => {
  const [createAboutUs, { loading, error, data }] = useMutation<
    CreateOneAboutUsResponse,
    { data: AboutUsCreateInput }
  >(CREATE_ONE_ABOUT_US, {
    onCompleted: () => {
      refetchAboutUs();
    },
  });

  return { createAboutUs, loading, error, data };
};
