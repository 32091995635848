import { gql } from "@apollo/client";

const CREATE_HERO = gql`
  mutation CreateHero($data: HeroCreateInput!) {
    createOneHero(data: $data) {
      id
      name {
        text
        language
      }
      description {
        text
        language
      }
      features {
        titles {
          text
          language
        }
        descriptions {
          text
          language
        }
      }
    }
  }
`;

export default CREATE_HERO;

// how pass data -------------------------------- :

// {
//   "data": {
//     "name": {
//       "create": [
//         {
//           "text": "Hero Name",
//           "language": "en"
//         }
//       ]
//     },
//     "description": {
//       "create": [
//         {
//           "text": "Hero Description",
//           "language": "en"
//         }
//       ]
//     },
//     "features": {
//       "create": [
//         {
//           "title": {
//             "create": {
//               "text": "Feature 1 Title",
//               "language": "en"
//             }
//           },
//           "description": {
//             "create": {
//               "text": "Feature 1 Description",
//               "language": "en"
//             }
//           }
//         },
//         {
//           "title": {
//             "create": {
//               "text": "Feature 2 Title",
//               "language": "en"
//             }
//           },
//           "description": {
//             "create": {
//               "text": "Feature 2 Description",
//               "language": "en"
//             }
//           }
//         }
//       ]
//     }
//   }
// }
// ----------------------------------------------------------------
