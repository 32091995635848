import { gql, useMutation } from "@apollo/client";

const CREATE_POST_MUTATION = gql`
  mutation CreatePost($data: PostCreateInput!) {
    createOnePost(data: $data) {
      id
      imageUrl
      index
      titles {
        title
        language
      }
      components {
        type
        translations {
          contentText
          language
          subtitle
        }
        images {
          url
        }
      }
    }
  }
`;

export const useCreateContent = (refetchContents: () => void) => {
  const [createOnePost, { loading, error, data }] = useMutation(
    CREATE_POST_MUTATION,
    {
      onCompleted: () => {
        // Call the refetch function after successful mutation
        refetchContents();
      },
    }
  );

  return { createOnePost, loading, error, data };
};
