import { Button, Input, Popconfirm, Table, message } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import { useGetContents } from "../../../graphql/queries/Contents/getContent";
import { useDeleteContent } from "../../../graphql/mutations/Contents/deleteContent";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ApolloError } from "@apollo/client";
import languages from "../../../constants/languages";

const GetContent = () => {
  const [search, setSearch] = useState<string>("");
  const { data, loading, refetch } = useGetContents();
  const { handleDeleteContent } = useDeleteContent(refetch);

  return (
    <Div>
      <Table
        rowKey="id"
        columns={[
          {
            title: "სურათი",
            key: "image",
            render: (_, record: any) => (
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {record.imageUrl ? (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${record.imageUrl}`}
                    alt="Image"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.currentTarget.src = "/path/to/fallback/image.jpg";
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No Image
                  </div>
                )}
              </div>
            ),
          },
          {
            title: "სათაური",
            key: "title",
            render: (_, record: any) => (
              <div className="flex flex-col gap-2">
                {languages.map((lang) => {
                  const titleObj = record.titles.find(
                    (title: any) =>
                      title.language.toLowerCase() === lang.code.toLowerCase()
                  );
                  return (
                    <div key={lang.code}>
                      <span className="font-semibold">
                        {lang.name}:{` `}
                      </span>
                      {titleObj ? titleObj.title : "N/A"}
                    </div>
                  );
                })}
              </div>
            ),
          },
          {
            title: "ქმედება",
            key: "action",
            render: (_: any, record: any) => (
              <Button.Group>
                <Link to={`/blogs/edit/${record.id}`}>
                  <Button type="primary">რედაქტირება</Button>
                </Link>
                <Popconfirm
                  title="გსურთ წაშლა?"
                  onConfirm={async () => {
                    const { success, error } = await handleDeleteContent(
                      record.id
                    );
                    if (success) {
                      message.success("სიახლე წაიშალა");
                    } else {
                      if (error instanceof ApolloError) {
                        message.error(`შეცდომა წაშლისას: ${error.message}`);
                      } else {
                        message.error("შეცდომა წაშლისას: უცნობი შეცდომა");
                      }
                    }
                  }}
                  okText="კი"
                  cancelText="არა"
                >
                  <Button type="primary" danger>
                    წაშლა
                  </Button>
                </Popconfirm>
              </Button.Group>
            ),
          },
        ]}
        dataSource={data}
        loading={loading}
        title={() => (
          <div>
            <div className="BlogTitle-addButton">
              <Title level={3}>ბლოგი</Title>
              <Link to={"/blogs/create"}>
                <Button type="primary">დამატება</Button>
              </Link>
            </div>
            <Input.Search
              placeholder="ძიება"
              enterButton
              allowClear
              onSearch={(value) => {
                setSearch(value);
                refetch({
                  where: {
                    titles: { some: { title: { contains: value } } },
                  },
                });
              }}
            />
          </div>
        )}
        tableLayout="fixed"
        bordered
      />
    </Div>
  );
};

export default GetContent;

const Div = styled.div`
  padding: 16px;
  .BlogTitle-addButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 14px 0;
  }
`;
