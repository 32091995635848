import { gql, useQuery } from "@apollo/client";

export const GET_CONTENTS = gql`
  query GetPosts {
    posts {
      id
      imageUrl
      titles {
        title
        language
      }
    }
  }
`;

export const useGetContents = () => {
  const { data, loading, error, refetch } = useQuery(GET_CONTENTS);

  return {
    data: data?.posts || [],
    loading,
    error,
    refetch,
  };
};
