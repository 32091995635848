import styled from "styled-components";
import CreateContent from "./Content/CreateContent";

const Blog = () => {
  return (
    <Section>
      <CreateContent />
    </Section>
  );
};

export default Blog;

const Section = styled.section`
  padding: 24px;
  text-align: left;
  .add-button {
    width: 100%;
    margin: 40px 0;
  }

  .primary-button {
    width: 100%;
    padding: 20px 0;
  }

  .labels::before {
    display: inline-block;
    margin: 0 0 16px 0;
    margin-inline-end: 4px;
    color: #fc2828;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .input-div,
  .toc {
    margin: 20px 0 0 0;
  }

  .image-upload {
    display: flex;
    gap: 8px;
    margin: 16px 0 0 0;
  }

  .country-flag {
    margin: 20px 0;
    text-align: center;
  }

  .country-flag img {
    margin: 0 5px -5px 0;
  }

  .component {
    margin: 80px 0 0 0;
  }

  .ql-editor {
    min-height: 140px;
  }
`;
