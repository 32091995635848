import { gql, useMutation } from "@apollo/client";

export const DELETE_PACKAGES = gql`
  mutation DeletePackageSize($id: String!) {
    deleteOnePackageSize(where: { id: $id }) {
      id
    }
  }
`;

export const useDeletePackages = (onCompleted?: () => void) => {
  const [deletePackages, { data, loading, error }] = useMutation(
    DELETE_PACKAGES,
    {
      onCompleted,
    }
  );

  const handleDeletePackages = async (id: string) => {
    try {
      const result = await deletePackages({ variables: { id } });
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeletePackages,
    data,
    loading,
    error,
  };
};
