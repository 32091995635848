import { ApolloError, useQuery } from "@apollo/client";
import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Upload,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_CONTENTS } from "../../../graphql/queries/Contents/getContent";
import { useCreateContent } from "../../../graphql/mutations/Contents/createContent";
import languages from "../../../constants/languages";
import ReactQuill from "react-quill";
import {
  ComponentData,
  FormValues,
  PostCreateInput,
} from "../../../types/blog/blogTypes";

const CreateContent = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { refetch } = useQuery(GET_CONTENTS);
  const { createOnePost, loading } = useCreateContent(refetch);
  const [componentType, setComponentType] = useState<"editor" | "image">(
    "image"
  );
  const [components, setComponents] = useState<ComponentData[]>([]);

  const handleUpload = useCallback(
    (info: any, fieldName: string) => {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);

        const currentUrls = form.getFieldValue(fieldName) || [];

        form.setFieldsValue({ [fieldName]: currentUrls });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    [form]
  );

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values: FormValues) => {
    try {
      const contentData: PostCreateInput = {
        index: Number(values.index),
        imageUrl: values.mainImage?.[0]?.response?.url,
        titles: {
          create: languages.map((language) => ({
            language: language.code,
            title: values[`title-${language.code}`],
          })),
        },
        components: {
          create: components.map((component) => {
            if (component.type === "image") {
              return {
                type: "IMAGE",
                images: {
                  create:
                    values[`images-${component.key}`]?.fileList?.map(
                      (file: any) => {
                        return {
                          url: file.response.url,
                        };
                      }
                    ) || [],
                },
              };
            } else {
              return {
                type: "TRANSLATION",
                translations: {
                  create: languages.map((language) => ({
                    language: language.code,
                    subtitle:
                      values[`subtitle-${component.key}-${language.code}`],
                    contentText:
                      values[`contentText-${component.key}-${language.code}`],
                  })),
                },
              };
            }
          }),
        },
      };

      const { data } = await createOnePost({
        variables: { data: contentData },
      });
      if (data && data.createOnePost) {
        message.success("ბლოგი შექმნილია");
        navigate("/blogs");
      } else {
        throw new Error("Content creation failed");
      }
    } catch (err) {
      console.error("Error details:", err);
      if (err instanceof ApolloError) {
        console.error("GraphQL errors:", err.graphQLErrors);
        console.error("Network error:", err.networkError);
      }
      message.error(
        "შეცდომა მოხდა: " + (err instanceof Error ? err.message : String(err))
      );
    }
  };

  const addComponent = () => {
    setComponents([
      ...components,
      { type: componentType, key: Date.now().toString() },
    ]);
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <Breadcrumb
        items={[{ title: "ბლოგი", href: "/blogs" }, { title: "შექმნა" }]}
      />
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="dynamic_form_complex"
        className="max-w-[1000px] w-full"
        autoComplete="off"
        layout="vertical"
        labelWrap
        onFinish={onFinish}
      >
        <Card size="small">
          <Form.Item
            label="ინდექსი"
            name="index"
            rules={[{ required: true, message: "Index აუცილებელია!" }]}
          >
            <Input placeholder="ინდექსი" />
          </Form.Item>

          <Form.Item
            name="mainImage"
            label="მთავარი სურათი"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Please upload a main image!" }]}
          >
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={(info) => handleUpload(info, "mainImage")}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>ატვირთეთ მთავარი სურათი</Button>
            </Upload>
          </Form.Item>

          {languages.map((language) => (
            <Form.Item
              key={language.code}
              label={`სათაური (${language.name})`}
              name={`title-${language.code}`}
              rules={[
                {
                  required: true,
                  message: `${language.name} სათაური აუცილებელია!`,
                },
              ]}
            >
              <Input placeholder={`სათაური (${language.name})`} />
            </Form.Item>
          ))}

          {/* Render Each Component */}
          {components.map((component) => (
            <Fragment key={component.key}>
              {component.type === "image" ? (
                <Form.Item name={`images-${component.key}`} label="Images">
                  <Upload
                    action={`${process.env.REACT_APP_API_URL}/upload`}
                    listType="picture"
                    onChange={(info) => handleUpload(info, component.key)}
                    multiple={true}
                  >
                    <Button icon={<UploadOutlined />}>Upload Images</Button>
                  </Upload>
                </Form.Item>
              ) : (
                languages.map((language) => (
                  <Fragment key={language.code}>
                    <Divider orientation="center">{language.name}</Divider>
                    <Form.Item
                      label={"ქვესათაური"}
                      name={`subtitle-${component.key}-${language.code}`}
                      rules={[
                        {
                          required: true,
                          message: language.code + " ქვესათაური აუცილებელია!",
                        },
                      ]}
                    >
                      <Input placeholder={"ქვესათაური"} />
                    </Form.Item>
                    <Form.Item
                      label={"ტექსტი"}
                      name={`contentText-${component.key}-${language.code}`}
                      rules={[
                        {
                          required: true,
                          message: language.code + " ტექსტი აუცილებელია!",
                        },
                      ]}
                    >
                      <ReactQuill />
                    </Form.Item>
                  </Fragment>
                ))
              )}
            </Fragment>
          ))}

          <Form.Item label="კომპონენტის ტიპი">
            <Radio.Group
              onChange={(e) => setComponentType(e.target.value)}
              value={componentType}
            >
              <Radio value="editor">ედიტორი</Radio>
              <Radio value="image">სურათი</Radio>
            </Radio.Group>
          </Form.Item>

          <Button
            type="dashed"
            onClick={addComponent}
            block
            icon={<PlusOutlined />}
          >
            კომპონენტის დამატება
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginTop: 20 }}
            size="large"
            loading={loading}
          >
            შენახვა
          </Button>
        </Card>
      </Form>
    </div>
  );
};

export default CreateContent;
