import { gql, useMutation } from "@apollo/client";
import {
  UpdateProductData,
  UpdateProductVars,
} from "../../../../types/product/productTypes";

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: String!, $data: ProductUpdateInput!) {
    updateOneProduct(where: { id: $id }, data: $data) {
      id
      mainImageUrl
      additionalImages
      translations {
        id
        language
        name
        description
      }
      attributes {
        id
      }
      species {
        id
      }
      packageSizes {
        id
      }
      layerCounts {
        id
      }
    }
  }
`;

export const useUpdateProduct = (refetchAboutUs: () => void) => {
  const [updateProduct, { loading, error, data }] = useMutation<
    UpdateProductData,
    UpdateProductVars
  >(UPDATE_PRODUCT, {
    onCompleted: () => {
      refetchAboutUs();
    },
    onError: (error) => {
      console.error("Error updating product:", error);
    },
  });

  return { updateProduct, loading, error, data };
};
