import { gql, useQuery } from "@apollo/client";

// Define the query to get a single post by its ID
export const GET_CONTENT_BY_ID = gql`
  query GetPost($id: String!) {
    getPost(where: { id: $id }) {
      id
      index
      imageUrl
      titles {
        id
        language
        title
      }
      components {
        id
        type
        images {
          id
          url
        }
        translations {
          id
          language
          subtitle
          contentText
        }
      }
    }
  }
`;

// Create a custom hook to get a single post by ID
export const useGetContentById = (id: string) => {
  const { data, loading, error, refetch } = useQuery(GET_CONTENT_BY_ID, {
    variables: { id },
    skip: !id, // Skip the query if no id is provided
  });

  return {
    data: data?.getPost || null,
    loading,
    error,
    refetch,
  };
};
