import { gql } from "@apollo/client";

const GET_ALL_HEROES = gql`
  query MyQuery {
    heroes {
      id
      name {
        id
        text
        language
      }
      description {
        id
        text
        language
      }
      features {
        id
        titles {
          id
          text
          language
        }
        descriptions {
          id
          text
          language
        }
      }
    }
  }
`;

export default GET_ALL_HEROES;
