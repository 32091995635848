import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const UPDATE_ONE_LAYER = gql`
  mutation UpdateLayerCount(
    $data: LayerCountUpdateInput!
    $where: LayerCountWhereUniqueInput!
  ) {
    updateOneLayerCount(data: $data, where: $where) {
      translations {
        name
        language
      }
    }
  }
`;

export const useUpdateLayer = (refetchAttributes: () => void) => {
  const [updateLayers, { loading, error, data }] = useMutation<{ data: any }>(
    UPDATE_ONE_LAYER,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { updateLayers, loading, error, data };
};
