import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UploadFile } from "antd/lib/upload/interface";
import { useGetOneGalleryImage } from "../../../graphql/queries/gallery/Gallery-image/findImage";
import { useUpdateGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/updateImage";

const { Item: FormItem } = Form;

const EditImage = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { data, loading: loadingImage } = useGetOneGalleryImage(id);
  const { updateGalleryImage, loading: updating } = useUpdateGalleryImage(
    () => {
      navigate("/gallery");
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        mobileIndex: data.galleryImage.mobileIndex,
        desktopIndex: data.galleryImage.desktopIndex,
      });

      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: `${process.env.REACT_APP_API_URL}/uploads/${data.galleryImage.url}`,
        } as UploadFile,
      ]);
    }
  }, [data, form]);

  const handleSubmit = async (values: any) => {
    const { mobileIndex, desktopIndex } = values;

    let imageUrl = data.galleryImage.url;

    if (fileList.length > 0 && fileList[0].originFileObj) {
      const formData = new FormData();
      formData.append("file", fileList[0].originFileObj as Blob);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        const responseText = await response.text();

        if (response.ok) {
          const result = JSON.parse(responseText);
          imageUrl = result.url;
        } else {
          console.error("Image upload failed:", responseText);
          return;
        }
      } catch (error) {
        console.error("Error during image upload:", error);
        return;
      }
    }
    updateGalleryImage({
      variables: {
        id,
        data: {
          url: { set: imageUrl },
          mobileIndex: { set: Number(mobileIndex) },
          desktopIndex: { set: Number(desktopIndex) },
        },
      },
    });
  };

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  if (loadingImage) return <Spin />;
  if (!data) return <p>Error loading image.</p>;

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2>სურათის რედაქტირება</h2>
      {updating && <Spin />}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          name="image"
          label="ატვირთეთ ახალი სურათი"
          rules={[{ required: false }]}
        >
          <Upload
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleFileChange}
            listType="picture-card"
            accept="image/*"
          >
            {fileList.length >= 1 ? null : (
              <div>
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </FormItem>

        <FormItem
          name="mobileIndex"
          label="ტელეფონის ინდექსი"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ ტელეფონის ინდექსი" },
          ]}
        >
          <Input type="number" min={0} />
        </FormItem>

        <FormItem
          name="desktopIndex"
          label="დესკტოპის ინდექსი"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ დესკტოპის ინდექსი" },
          ]}
        >
          <Input type="number" min={0} />
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit" loading={updating}>
            ატვირთვა
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default EditImage;
