import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Select, Upload } from "antd";
import Title from "antd/es/typography/Title";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { useUpdateOrder } from "../../../graphql/mutations/Landing/WhoWeAre/updateWhoWeAre";
import { useGetAllAttribute } from "../../../graphql/queries/Products/Attributes/getAttribute";
import { useGetAllWhoWeAre } from "../../../graphql/queries/Landing/WhoWeAre/getWoWeAre";

const { TextArea } = Input;

const EditWhoWeAre = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: attributeData } = useGetAllAttribute();
  const { refetch } = useGetAllWhoWeAre();
  const [imageFile, setImageFile] = useState<any>(null);
  const [videoFile, setVideoFile] = useState<any>(null);

  const { data } = useGetAllWhoWeAre();
  const { updateOrders, loading: updateLoading } = useUpdateOrder(refetch);

  const whoWeAreData = data[0];

  useEffect(() => {
    if (whoWeAreData) {
      form.setFieldsValue({
        ka: whoWeAreData.kaDescription,
        en: whoWeAreData.enDescription,
        ru: whoWeAreData.ruDescription,
        image: whoWeAreData.imageUrl,
        video: whoWeAreData.videoUrl,
        attributes: whoWeAreData.productAttributes.map((attr: any) => attr.id),
      });
      setImageFile(
        whoWeAreData.imageUrl
          ? {
              uid: "-1",
              name: "Current Image",
              status: "done",
              url: whoWeAreData.imageUrl,
            }
          : null
      );
      setVideoFile(
        whoWeAreData.videoUrl
          ? {
              uid: "-1",
              name: "Current Video",
              status: "done",
              url: whoWeAreData.videoUrl,
            }
          : null
      );
    }
  }, [whoWeAreData, form]);

  const onFinish = async (values: any) => {
    // console.log(values);

    try {
      await updateOrders({
        variables: {
          where: { id },
          data: {
            kaDescription: { set: values.ka },
            enDescription: { set: values.en },
            ruDescription: { set: values.ru },
            imageUrl: {
              set: form.getFieldValue("image"),
            },
            videoUrl: { set: form.getFieldValue("video") },
            productAttributes: {
              set: values.attributes.map((id: string) => ({ id })),
            },
          },
        },
      });
      navigate("/landing/who-we-are");
      message.success("წარმატებით განახლდა");
    } catch (error) {
      message.error("განახლება ვერ მოხერხდა");
    }
  };

  const handleUpload = async (file: File, fileType: "image" | "video") => {
    try {
      const uploadUrl = `${process.env.REACT_APP_API_URL}/upload`;
      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }

      const responseData = await response.json();
      const fileUrl = `${process.env.REACT_APP_API_URL}/uploads/${responseData.url}`;

      form.setFieldsValue({ [fileType]: fileUrl });
      if (fileType === "image") {
        setImageFile({
          uid: "-1",
          name: file.name,
          status: "done",
          url: fileUrl,
        });
      } else {
        setVideoFile({
          uid: "-1",
          name: file.name,
          status: "done",
          url: fileUrl,
        });
      }

      message.success(`${fileType} წარმატებით აიტვირთა`);
    } catch (error) {
      console.error("Upload error:", error);
      message.error(`${fileType}-ის ატვირთვა ვერ მოხერხდა`);
    }
  };

  const uploadProps = (fileType: "image" | "video") => ({
    onRemove: () => {
      if (fileType === "image") {
        setImageFile(null);
      } else {
        setVideoFile(null);
      }
      form.setFieldsValue({ [fileType]: "" });
    },
    beforeUpload: (file: File) => {
      const isCorrectType =
        fileType === "image"
          ? file.type.startsWith("image/")
          : file.type.startsWith("video/");
      if (!isCorrectType) {
        message.error(
          `თქვენ შეგიძლიათ მხოლოდ ${
            fileType === "image" ? "სურათის" : "ვიდეოს"
          } ატვირთვა!`
        );
        return Upload.LIST_IGNORE;
      }
      handleUpload(file, fileType);
      return false;
    },
    fileList:
      fileType === "image"
        ? imageFile
          ? [imageFile]
          : []
        : videoFile
        ? [videoFile]
        : [],
  });

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <Title level={2}>ვინ ვართ ჩვენ - განახლება</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <Form.Item
          label="აღწერა (ქართული)"
          name="ka"
          rules={[
            { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ქართულად" },
          ]}
        >
          <TextArea rows={4} placeholder="აღწერა" />
        </Form.Item>

        <Form.Item
          label="აღწერა (ინგლისური)"
          name="en"
          rules={[
            { required: true, message: "გთხოვთ აღწერა შეიყვანოთ ინგლისურად" },
          ]}
        >
          <TextArea rows={4} placeholder="აღწერა" />
        </Form.Item>

        <Form.Item
          label="აღწერა (რუსული)"
          name="ru"
          rules={[
            { required: true, message: "გთხოვთ აღწერა შეიყვანოთ რუსულად" },
          ]}
        >
          <TextArea rows={4} placeholder="აღწერა" />
        </Form.Item>

        <Form.Item
          label="სურათი"
          name="image"
          rules={[{ required: true, message: "გთხოვთ ატვირთოთ სურათი" }]}
        >
          <Upload {...uploadProps("image")} listType="picture-card">
            {!imageFile && (
              <Button icon={<UploadOutlined />}>ატვირთეთ სურათი</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ვიდეო"
          name="video"
          rules={[{ required: true, message: "გთხოვთ ატვირთოთ ვიდეო" }]}
        >
          <Upload {...uploadProps("video")} listType="picture-card">
            {!videoFile && (
              <Button icon={<UploadOutlined />}>ატვირთეთ ვიდეო</Button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="ატრიბუტები"
          name="attributes"
          rules={[
            { required: true, message: "გთხოვთ აირჩიოთ მინიმუმ ერთი ატრიბუტი" },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="აირჩიეთ ატრიბუტები"
            style={{ width: "100%" }}
          >
            {attributeData?.map((attr: any) => (
              <Select.Option key={attr.id} value={attr.id}>
                {attr.translations[0].name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            განახლება
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditWhoWeAre;
