import { useState } from "react";
import { Form, Input, Button, Upload, message, InputNumber } from "antd";
import { AboutUsCreateInput } from "../../types/about-us/aboutUsTypes";
import { useNavigate } from "react-router-dom";
import { useGetAllAboutUs } from "../../graphql/queries/About-us/getAboutUs";
import { useCreateAboutUs } from "../../graphql/mutations/About-us/createAboutUs";

const AboutUsForm = () => {
  const [form] = Form.useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const navigate = useNavigate();
  const { createAboutUs, loading } = useCreateAboutUs(() => {});
  const { data, refetch } = useGetAllAboutUs();

  const maxImages = data?.length === 1 ? 6 : 4;
  const [images, setImages] = useState({
    ka: undefined as string | undefined,
    en: undefined as string | undefined,
    ru: undefined as string | undefined,
  });
  const handleUploadMainImage =
    (language: "ka" | "en" | "ru") => (info: any) => {
      if (info.file.status === "done") {
        const uploadedUrl = info.file.response.url;
        setImages((prev) => ({ ...prev, [language]: uploadedUrl }));
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    };

  const allData = data;
  const handleUpload = (info: any) => {
    if (info.file.status === "done") {
      const uploadedUrl = info.file.response.url;
      setUploadedImages((prev) => [...prev, uploadedUrl]);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = async (values: any) => {
    if (uploadedImages.length !== maxImages) {
      message.error(`გთხოვთ ატვირთოთ ${maxImages} სურათი.`);
      return;
    }
    try {
      const aboutUsData: AboutUsCreateInput = {
        imageUrl: {
          set: uploadedImages,
        },
        firstYear: Number(values.firstYear),
        lastYear: Number(values.lastYear),

        translations: {
          create: [
            {
              language: "KA",
              title: values.title_ka,
              description: values.description_ka,
              ...(allData?.length < 1 && { mainImage: images.ka }),
            },
            {
              language: "EN",
              title: values.title_en,
              description: values.description_en,
              ...(allData?.length < 1 && { mainImage: images.en }),
            },
            {
              language: "RU",
              title: values.title_ru,
              description: values.description_ru,
              ...(allData?.length < 1 && { mainImage: images.ru }),
            },
          ],
        },
      };
      if (allData?.length < 1) {
        aboutUsData.user = Number(values.user);
        aboutUsData.magazine = Number(values.magazine);
        aboutUsData.existenceYear = Number(values.existenceYear);
      }

      const { data } = await createAboutUs({
        variables: { data: aboutUsData },
      });

      if (data && data.createOneAboutUs) {
        message.success("ინფორმაცია წარმატებით შეინახა");
        await refetch();
        navigate("/about-us");

        setIsSubmitted(true);
      } else {
        throw new Error("Content creation failed");
      }
    } catch (err) {
      console.error("Error details:", err);
      message.error(
        "შეცდომა მოხდა: " + (err instanceof Error ? err.message : String(err))
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ padding: "30px" }}
    >
      <h2 style={{ margin: "10px 0" }}>
        კომპანიის შესახებ ინფორმაციის დამატება
      </h2>

      <Form.Item
        label="სათაური (ქართული)"
        name="title_ka"
        rules={[
          { required: true, message: "გთხოვთ სათაური შეიყვანოთ ქართულად" },
        ]}
      >
        <Input placeholder="სათაური" />
      </Form.Item>

      <Form.Item
        label="სათაური (ინგლისური)"
        name="title_en"
        rules={[
          { required: true, message: "გთხოვთ სათაური შეიყვანოთ ინგლისურად" },
        ]}
      >
        <Input placeholder="სათაური" />
      </Form.Item>

      <Form.Item
        label="სათაური (რუსული)"
        name="title_ru"
        rules={[
          { required: true, message: "გთხოვთ სათაური შეიყვანოთ რუსულად" },
        ]}
      >
        <Input placeholder="სათაური" />
      </Form.Item>

      <Form.Item
        label="აღწერა (ქართული)"
        name="description_ka"
        rules={[
          {
            required: true,
            message: "გთხოვთ აღწერა შეიყვანოთ ქართულად",
          },
        ]}
      >
        <Input.TextArea placeholder="აღწერა" />
      </Form.Item>

      <Form.Item
        label="აღწერა (ინგლისური)"
        name="description_en"
        rules={[
          {
            required: true,
            message: "გთხოვთ აღწერა შეიყვანოთ ინგლისურად",
          },
        ]}
      >
        <Input.TextArea placeholder="აღწერა" />
      </Form.Item>

      <Form.Item
        label="აღწერა (რუსული)"
        name="description_ru"
        rules={[
          {
            required: true,
            message: "გთხოვთ აღწერა შეიყვანოთ რუსულად",
          },
        ]}
      >
        <Input.TextArea placeholder="აღწერა" />
      </Form.Item>
      <Form.Item
        label="პირველი წელი"
        name="firstYear"
        rules={[
          {
            required: true,
            message: "გთხოვთ შეიყვანოთ პირველი წელი",
          },
          {
            type: "number",
            message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
          },
        ]}
      >
        <InputNumber placeholder="კომპანიის დაარსების წელი" />
      </Form.Item>
      <Form.Item
        label="ბოლო წელი"
        name="lastYear"
        rules={[
          {
            required: true,
            message: "გთხოვთ შეიყვანოთ ბოლო წელი",
          },
          {
            type: "number",
            message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
          },
        ]}
      >
        <InputNumber placeholder="კომპანიის დაარსების წელი" />
      </Form.Item>
      {data.length < 1 && (
        <>
          <Form.Item
            label="მთავარი სურათი (ქართული)"
            rules={[
              {
                required: true,
                message: "გთხოვთ ატვირთოთ მთავარი სურათი ქართულად",
              },
            ]}
          >
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={handleUploadMainImage("ka")}
            >
              <Button>ატვირთეთ მთავარი სურათი ქართულად</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="მთავარი სურათი (ინგლისური)"
            rules={[
              {
                required: true,
                message: "გთხოვთ ატვირთოთ მთავარი სურათი ინგლისურად",
              },
            ]}
          >
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={handleUploadMainImage("en")}
            >
              <Button>ატვირთეთ მთავარი სურათი ინგლისურად</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="მთავარი სურათი (რუსული)"
            rules={[
              {
                required: true,
                message: "გთხოვთ ატვირთოთ მთავარი სურათი რუსულად",
              },
            ]}
          >
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={handleUploadMainImage("ru")}
            >
              <Button>ატვირთეთ მთავარი სურათი რუსულად</Button>
            </Upload>
          </Form.Item>
          <div style={{ display: "flex", gap: "20px" }}>
            <Form.Item
              label="არსებობის წელი"
              name="existenceYear"
              rules={[
                {
                  required: true,
                  message: "გთხოვთ შეიყვანოთ კომპანიის არსებობის წელი",
                },
                {
                  type: "number",
                  message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                },
              ]}
            >
              <InputNumber placeholder="არსებობის წელი" />
            </Form.Item>

            <Form.Item
              label="მომხმარებლების რაოდენობა"
              name="user"
              rules={[
                {
                  required: true,
                  message: "გთხოვთ შეიყვანოთ მომხმარებლების რაოდენობა",
                },
                {
                  type: "number",
                  message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                },
              ]}
            >
              <InputNumber placeholder="მომხმარებლების რაოდენობა" />
            </Form.Item>

            <Form.Item
              label="მაღაზია/ფილიალი"
              name="magazine"
              rules={[
                {
                  required: true,
                  message: "გთხოვთ შეიყვანოთ მაღაზია/ფილიალი",
                },
                {
                  type: "number",
                  message: "გთხოვთ შეიყვანოთ მხოლოდ ციფრები",
                },
              ]}
            >
              <InputNumber placeholder="მაღაზია/ფილიალი" />
            </Form.Item>
          </div>
        </>
      )}
      <Form.Item name="images" label={`ატვირთეთ ${maxImages} სურათი`}>
        <Upload
          action={`${process.env.REACT_APP_API_URL}/upload`}
          listType="picture"
          onChange={handleUpload}
          multiple={true}
          disabled={maxImages === uploadedImages.length}
        >
          <Button
            style={{
              backgroundColor:
                maxImages === uploadedImages.length ? "#d9d9d9" : "#1890ff",
              color: maxImages === uploadedImages.length ? "#bfbfbf" : "#fff",
              borderColor:
                maxImages === uploadedImages.length ? "#d9d9d9" : "#1890ff",
              cursor:
                maxImages === uploadedImages.length ? "not-allowed" : "pointer",
            }}
          >
            სურათის ატვირთვა
          </Button>
        </Upload>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        disabled={isSubmitted}
      >
        შენახვა
      </Button>
    </Form>
  );
};

export default AboutUsForm;
