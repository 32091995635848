import Gallery from "../components/gallery/Gallery";

const GalleryPage = () => {
  return (
    <div>
      <Gallery />
    </div>
  );
};

export default GalleryPage;
