import { gql, useMutation } from "@apollo/client";

export const CREATE_GALLERY_IMAGE = gql`
  mutation CreateGalleryImage($data: GalleryImageCreateInput!) {
    createOneGalleryImage(data: $data) {
      id
      url
      mobileIndex
      desktopIndex
      createdAt
      updatedAt
    }
  }
`;

export const useCreateGalleryImage = (onCompleted?: () => void) => {
  const [createGalleryImage, { data, loading, error }] = useMutation(
    CREATE_GALLERY_IMAGE,
    {
      onCompleted,
    }
  );

  return {
    createGalleryImage,
    data,
    loading,
    error,
  };
};
