import { gql, useQuery } from "@apollo/client";

// Define the GraphQL query
const GET_ALL_ATTRIBUTE = gql`
  query GetAllAttribute {
    productAttributes {
      id
      imageUrl
      translations {
        id
        language
        name
      }
    }
  }
`;

// Define TypeScript types for the query result
interface Translation {
  id: string;
  language: string;
  name: string;
}

interface ProductAttribute {
  id: string;
  imageUrl: string;
  translations: Translation[];
}

interface GetAllAttributeData {
  productAttributes: ProductAttribute[];
}

interface GetAllAttributeVars {}

// Custom hook for fetching product attributes
export const useGetAllAttribute = () => {
  const { data, loading, error, refetch } = useQuery<
    GetAllAttributeData,
    GetAllAttributeVars
  >(GET_ALL_ATTRIBUTE);

  return {
    data: data?.productAttributes || [],
    loading,
    error,
    refetch,
  };
};
