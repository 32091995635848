import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const CREATE_ONE_PACKAGE = gql`
  mutation CreatePackageSize($data: PackageSizeCreateInput!) {
    createOnePackageSize(data: $data) {
      id
      translations {
        name
        language
      }
    }
  }
`;

export const useCreatePackage = (refetchAttributes: () => void) => {
  const [createPackage, { loading, error, data }] = useMutation<{ data: any }>(
    CREATE_ONE_PACKAGE,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { createPackage, loading, error, data };
};
