import { gql, useQuery } from "@apollo/client";
import { GetAllGalleryImagesResponse } from "../../../../types/gallery/videoTypes";

const GET_ALL_GALLERY_IMAGES = gql`
  query GetAllGalleryImages {
    galleryImages {
      id
      url
      mobileIndex
      desktopIndex
    }
  }
`;

export const useGetAllGalleryImages = () => {
  const { data, loading, error, refetch } =
    useQuery<GetAllGalleryImagesResponse>(GET_ALL_GALLERY_IMAGES);

  return {
    data: data?.galleryImages || [],
    loading,
    error,
    refetch,
  };
};
