import { gql, useQuery } from "@apollo/client";

const FIND_LAYER = gql`
  query LayerCount($where: LayerCountWhereUniqueInput!) {
    layerCount(where: $where) {
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useFindLayer = (id: string) => {
  const { data, loading, error, refetch } = useQuery(FIND_LAYER, {
    variables: { where: { id } },
  });

  return {
    data: data?.layerCount || [],
    loading,
    error,
    refetch,
  };
};
