"use client";
import React, { useState } from "react";
import { Form, Input, Button, Upload, message, Typography, Image } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT_ATTRIBUTE } from "../../../graphql/mutations/Product/Attribute/createAttribute";
import { useGetAllAttribute } from "../../../graphql/queries/Products/Attributes/getAttribute";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const CreateAttribute: React.FC = () => {
  const [form] = Form.useForm();
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const { refetch } = useGetAllAttribute();
  const navigate = useNavigate();
  const [createProductAttribute, { loading }] = useMutation(
    CREATE_PRODUCT_ATTRIBUTE
  );

  const handleUpload = (info: any) => {
    if (info.file.status === "done") {
      const uploadedUrl = info.file.response.url;
      setUploadedImage(uploadedUrl);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    form.setFieldsValue({ image: null });
  };

  const onFinish = async (values: any) => {
    try {
      const { ka, en, ru } = values;
      const imageUrl = uploadedImage;

      await createProductAttribute({
        variables: {
          data: {
            imageUrl,
            translations: {
              create: [
                { language: "KA", name: ka },
                { language: "EN", name: en },
                { language: "RU", name: ru },
              ],
            },
          },
        },
      });
      navigate("/attributes");
      refetch();
      message.success("პროდუქტის ატრიბუტი წარმატებით შეიქმნა");
      form.resetFields();
      setUploadedImage(null);
    } catch (error) {
      message.error("პროდუქტის ატრიბუტის შექმნა ვერ მოხერხდა");
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <Title level={2}>ატრიბუტის დამატება</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <Form.Item
          label="სახელი (ქართული)"
          name="ka"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (ინგლისური)"
          name="en"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (რუსული)"
          name="ru"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ რუსულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item name="image" label={`ატვირთეთ 1 სურათი`}>
          <Upload
            action={`${process.env.REACT_APP_API_URL}/upload`}
            listType="picture"
            onChange={handleUpload}
            showUploadList={false}
            multiple={false}
          >
            <Button
              icon={<UploadOutlined />}
              disabled={uploadedImage !== null}
              style={{
                backgroundColor: uploadedImage ? "#d9d9d9" : "#1890ff",
                color: uploadedImage ? "#bfbfbf" : "#fff",
                borderColor: uploadedImage ? "#d9d9d9" : "#1890ff",
                cursor: uploadedImage ? "not-allowed" : "pointer",
              }}
            >
              სურათის ატვირთვა
            </Button>
          </Upload>
        </Form.Item>

        {uploadedImage && (
          <Form.Item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                src={`${process.env.REACT_APP_API_URL}/uploads/${uploadedImage}`}
                alt="Uploaded Image"
                style={{
                  maxWidth: "200px",
                  height: "auto",
                  marginRight: "10px",
                }}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={handleDeleteImage}
                danger
                style={{ border: "none" }}
              >
                წაშლა
              </Button>
            </div>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!uploadedImage}
          >
            შენახვა
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateAttribute;
