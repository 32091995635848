import Product from "../components/Products/Product";

const ProductsPage = () => {
  return (
    <div>
      <Product />
    </div>
  );
};

export default ProductsPage;
