import ProductList from "../../components/Products/Products/ProductList";

const Product = () => {
  return (
    <div>
      <ProductList />
    </div>
  );
};

export default Product;
