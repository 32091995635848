import { gql } from "@apollo/client";

import { useMutation } from "@apollo/client";
import {
  ProductCreateInput,
  CreateProductResponse,
} from "../../../../types/product/productTypes";

export const CREATE_PRODUCT = gql`
  mutation CreateOneProduct($data: ProductCreateInput!) {
    createOneProduct(data: $data) {
      id
      code
      mainImageUrl
      additionalImages
      translations {
        id
        language
        name
        description
      }
      attributes {
        id
        imageUrl
      }
      species {
        id
      }
      packageSizes {
        id
      }
      layerCounts {
        id
      }
    }
  }
`;

export const useCreateProduct = (refetchProducts: () => void) => {
  const [createProduct, { loading, error, data }] = useMutation<
    CreateProductResponse,
    { data: ProductCreateInput }
  >(CREATE_PRODUCT, {
    onCompleted: () => {
      refetchProducts();
    },
  });

  return { createProduct, loading, error, data };
};
