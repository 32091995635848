import { gql, useQuery } from "@apollo/client";

const GET_ALL_LAYERS = gql`
  query MyQuery {
    layerCounts {
      id
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useGetAllLayers = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_LAYERS);

  return {
    data: data?.layerCounts || [],
    loading,
    error,
    refetch,
  };
};
