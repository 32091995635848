import styled from "styled-components";
import { Button } from "antd";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import GET_ALL_HEROES from "../../graphql/queries/Hero/getAllHeros";

const Hero = () => {
  const { data } = useQuery(GET_ALL_HEROES);

  return (
    <Section>
      <h1>შესავალი</h1>
      <Link to="/hero/create">
        {data?.heroes.length === 0 && (
          <Button type="primary" icon={<PlusOutlined />}>
            კომპონენტის დამატება
          </Button>
        )}
      </Link>

      {data?.heroes.length === 0 ? (
        <h2>კომპონენტი არ არსებობს</h2>
      ) : (
        <div className="flex">
          <h2>სათაური: {data?.heroes?.[0]?.name?.[0]?.text}</h2>
          <Link to={`/hero/edit/${data?.heroes?.[0]?.id}`}>
            <Button type="primary" icon={<EditFilled />}>
              რედაქტირება
            </Button>
          </Link>
        </div>
      )}
    </Section>
  );
};

export default Hero;

const Section = styled.section`
  margin: 8px 16px 0 16px;
  h1 {
    font-size: 28px;
    margin: 0 0 20px 0;
  }

  .flex {
    padding: 20px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;
