import { Button, Flex, Form, Input, message } from "antd";
import { axiosInstance } from "../../utils/axiosConfig";
import styled from "styled-components";
import { ILoginFormValues, ILoginResponseError } from "../login/Login.types";
import { useNavigate } from "react-router-dom";

const CreateAccounts = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();

  const onFinish = async (values: ILoginFormValues) => {
    try {
      const { data } = await axiosInstance.post("/users/create", values);
      if (!data.success) {
        return message.error("ექაუნთი უკვე არსებობს!");
      }

      navigation("/accounts");
      message.success(data.message);
      form.resetFields();
    } catch (error) {
      message.error(
        (error as ILoginResponseError).response?.data?.message ||
          "ექაუნთი უკვე არსებობს!"
      );
    }
  };

  return (
    <Main>
      <Flex justify="center" align="center" className="flex">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="ელ.ფოსტა"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "შეავსეთ ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="პაროლი"
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: "შეავსეთ ველი! (მინიმუმ 6 ასო)",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            ექაუნთის შექმნა
          </Button>
        </Form>
      </Flex>
    </Main>
  );
};

const Main = styled.main`
  .flex {
    height: 100vh;
    padding: 0 10px;
  }

  button {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 400px;
  }
`;
export default CreateAccounts;
