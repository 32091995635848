"use client";
import React from "react";
import {
  List,
  Card,
  Spin,
  Alert,
  Typography,
  Button,
  Modal,
  message,
} from "antd";
import { Image } from "antd";
import { useGetAllAttribute } from "../../../graphql/queries/Products/Attributes/getAttribute";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDeleteProductAttribute } from "../../../graphql/mutations/Product/Attribute/deleteAttribute";

const { Title } = Typography;

const AttributeList: React.FC = () => {
  const { data, loading, error, refetch } = useGetAllAttribute();
  const { handleDeleteAttribute, loading: deleteLoading } =
    useDeleteProductAttribute(refetch);

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "დარწმუნებული ხარ რომ წაშლა გინდა?",
      onOk: async () => {
        try {
          await handleDeleteAttribute(id);
          message.success("ატრიბუტი წაიშალა წარმატებით");
          refetch();
        } catch (error) {
          message.error("ატრიბუტი არ წაიშალა");
        }
      },
    });
  };

  if (loading) return <Spin size="large" />;
  if (error)
    return (
      <Alert
        message="Error"
        description="Failed to load attributes."
        type="error"
      />
    );

  return (
    <div style={{ margin: "10px 20px" }}>
      <Title level={2}>ატრიბუტები</Title>
      <Link to="/attribute/create">
        <Button style={{ margin: "10px 0" }} type="primary">
          დამატება
        </Button>
      </Link>
      <List
        grid={{ gutter: 16, column: 5 }}
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item>
            <Card
              cover={
                <Image
                  src={`${process.env.REACT_APP_API_URL}/uploads/${item.imageUrl}`}
                  alt="Attribute Image"
                  style={{ height: 150, objectFit: "cover" }}
                />
              }
              actions={[
                <Link to={`/attribute/edit/${item.id}`}>
                  <Button icon={<EditOutlined />}>რედაქტირება</Button>
                </Link>,
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(item.id)}
                >
                  წაშლა
                </Button>,
              ]}
            >
              <div>
                {item.translations.map((translation: any) => (
                  <p key={translation.id}>
                    {translation.language}: {translation.name}
                  </p>
                ))}
              </div>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default AttributeList;
