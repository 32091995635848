import { Button, Form, Input, message } from "antd";
import Title from "antd/es/typography/Title";
import { useCreateSpecies } from "../../../../graphql/mutations/Filter/Species/createSpecies";
import { useNavigate } from "react-router-dom";
import { useGetAllSpecies } from "../../../../graphql/queries/Filter/Species/getSpecies";

const CreateSpecies = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const { refetch } = useGetAllSpecies();
  const { createSpecies } = useCreateSpecies(refetch);

  const onFinish = async (values: any) => {
    const { ka, ru, en } = values;

    try {
      await createSpecies({
        variables: {
          data: {
            translations: {
              create: [
                { language: "KA", name: ka },
                { language: "EN", name: en },
                { language: "RU", name: ru },
              ],
            },
          },
        },
      });
      navigation("/species");
      message.success("პროდუქტის სახეობა წარმატებით შეიქმნა");
      form.resetFields();
    } catch (error) {
      message.error("პროდუქტის სახეობის შექმნა ვერ მოხერხდა");
    }
  };

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <Title level={2}>სახეობის დამატება</Title>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <Form.Item
          label="სახელი (ქართული)"
          name="ka"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ქართულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (ინგლისური)"
          name="en"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ ინგლისურად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item
          label="სახელი (რუსული)"
          name="ru"
          rules={[
            { required: true, message: "გთხოვთ სახელი შეიყვანოთ რუსულად" },
          ]}
        >
          <Input placeholder="სახელი" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // loading={loading}
            // disabled={!uploadedImage}
          >
            შენახვა
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateSpecies;
