import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, message, Spin } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import languages from "../../../constants/languages";
import GET_ALL_HEROES from "../../../graphql/queries/Hero/getAllHeros";
import UPDATE_HERO from "../../../graphql/mutations/Hero/updateHero";

const EditHero: React.FC = () => {
  const [form] = Form.useForm();
  const [updateOneHero] = useMutation(UPDATE_HERO);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const featureCount = 3;

  const {
    data: heroesData,
    loading: fetchLoading,
    error,
  } = useQuery(GET_ALL_HEROES);

  useEffect(() => {
    if (heroesData && heroesData.heroes && heroesData.heroes.length > 0) {
      const hero = heroesData.heroes[0];
      const formValues: any = {};

      // Set name and description fields
      languages.forEach((lang) => {
        const nameTranslation = hero.name.find(
          (t: any) => t.language === lang.code.toLowerCase()
        );
        const descTranslation = hero.description.find(
          (t: any) => t.language === lang.code.toLowerCase()
        );
        formValues[`title_${lang.code.toLowerCase()}`] =
          nameTranslation?.text || "";
        formValues[`description_${lang.code.toLowerCase()}`] =
          descTranslation?.text || "";
      });

      // Set feature fields
      hero.features.forEach((feature: any, index: number) => {
        languages.forEach((lang) => {
          const titleTranslation = feature.titles.find(
            (t: any) => t.language === lang.code.toLowerCase()
          );
          const descTranslation = feature.descriptions.find(
            (t: any) => t.language === lang.code.toLowerCase()
          );
          formValues[`feature_${index + 1}_title_${lang.code.toLowerCase()}`] =
            titleTranslation?.text || "";
          formValues[
            `feature_${index + 1}_description_${lang.code.toLowerCase()}`
          ] = descTranslation?.text || "";
        });
      });

      form.setFieldsValue(formValues);
    }
  }, [heroesData, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const hero = heroesData?.heroes[0];

    const updateData = {
      name: {
        update: languages.map((lang) => ({
          where: {
            id:
              hero.name.find(
                (t: { language: string }) =>
                  t.language === lang.code.toLowerCase()
              )?.id || "", // Ensure id is valid
          },
          data: {
            text: { set: values[`title_${lang.code.toLowerCase()}`] },
          },
        })),
      },
      description: {
        update: languages.map((lang) => ({
          where: {
            id:
              hero.description.find(
                (t: { language: string }) =>
                  t.language === lang.code.toLowerCase()
              )?.id || "", // Ensure id is valid
          },
          data: {
            text: { set: values[`description_${lang.code.toLowerCase()}`] },
          },
        })),
      },
      features: {
        update: hero.features.map((feature: any, index: number) => ({
          where: { id: feature.id }, // Ensure id is valid
          data: {
            titles: {
              update: languages.map((lang) => ({
                where: {
                  id:
                    feature.titles.find(
                      (t: { language: string }) =>
                        t.language === lang.code.toLowerCase()
                    )?.id || "", // Ensure id is valid
                },
                data: {
                  text: {
                    set: values[
                      `feature_${index + 1}_title_${lang.code.toLowerCase()}`
                    ],
                  },
                },
              })),
            },
            descriptions: {
              update: languages.map((lang) => ({
                where: {
                  id:
                    feature.descriptions.find(
                      (t: { language: string }) =>
                        t.language === lang.code.toLowerCase()
                    )?.id || "", // Ensure id is valid
                },
                data: {
                  text: {
                    set: values[
                      `feature_${
                        index + 1
                      }_description_${lang.code.toLowerCase()}`
                    ],
                  },
                },
              })),
            },
          },
        })),
      },
    };

    try {
      const result = await updateOneHero({
        variables: {
          id: id,
          data: updateData,
        },
      });
      message.success("Hero updated successfully!");
      navigation("/hero");
    } catch (error: any) {
      console.error("Error updating hero:", error);
      message.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (fetchLoading) return <Spin />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <section>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <h2 style={{ margin: "10px 0" }}>შესავლის ინფორმაციის რედაქტირება</h2>

        {/* Hero Title Fields */}
        {languages.map((lang) => (
          <Form.Item
            key={`title_${lang.code}`}
            label={`სათაური (${lang.name})`}
            name={`title_${lang.code.toLowerCase()}`}
            rules={[
              {
                required: true,
                message: `გთხოვთ სათაური შეიყვანოთ ${lang.name}-ად`,
              },
            ]}
          >
            <Input placeholder="სათაური" />
          </Form.Item>
        ))}

        {/* Hero Description Fields */}
        {languages.map((lang) => (
          <Form.Item
            key={`description_${lang.code}`}
            label={`აღწერა (${lang.name})`}
            name={`description_${lang.code.toLowerCase()}`}
            rules={[
              {
                required: true,
                message: `გთხოვთ აღწერა შეიყვანოთ ${lang.name}-ად`,
              },
            ]}
          >
            <Input.TextArea placeholder="აღწერა" />
          </Form.Item>
        ))}

        <h2 style={{ margin: "10px 0" }}>მახასიათებლები</h2>

        {/* Dynamic Feature Title and Description Fields */}
        {Array.from({ length: featureCount }).map((_, i) => (
          <div key={`feature_${i + 1}`}>
            <h3>მახასიათებელი {i + 1}</h3>

            {/* Feature Title Fields */}
            {languages.map((lang) => (
              <Form.Item
                key={`feature_${i + 1}_title_${lang.code}`}
                label={`მახასიათებლის სათაური (${lang.name})`}
                name={`feature_${i + 1}_title_${lang.code.toLowerCase()}`}
                rules={[
                  {
                    required: true,
                    message: `გთხოვთ მახასიათებლის სათაური შეიყვანოთ ${lang.name}-ად`,
                  },
                ]}
              >
                <Input placeholder="მახასიათებლის სათაური" />
              </Form.Item>
            ))}

            {/* Feature Description Fields */}
            {languages.map((lang) => (
              <Form.Item
                key={`feature_${i + 1}_description_${lang.code}`}
                label={`მახასიათებლის აღწერა (${lang.name})`}
                name={`feature_${i + 1}_description_${lang.code.toLowerCase()}`}
                rules={[
                  {
                    required: true,
                    message: `გთხოვთ მახასიათებლის აღწერა შეიყვანოთ ${lang.name}-ად`,
                  },
                ]}
              >
                <Input.TextArea placeholder="მახასიათებლის აღწერა" />
              </Form.Item>
            ))}
          </div>
        ))}

        <Button type="primary" htmlType="submit" loading={loading}>
          განახლება
        </Button>
      </Form>
    </section>
  );
};

export default EditHero;
