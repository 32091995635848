import { gql, useQuery } from "@apollo/client";

const GET_PRODUCTS = gql`
  query GetAllProducts {
    products {
      id
      code
      mainImageUrl
      additionalImages
      translations {
        id
        language
        name
        description
      }
      attributes {
        id
        imageUrl
        translations {
          id
          language
          name
        }
      }
      species {
        id
        translations {
          id
          language
          name
        }
      }
      packageSizes {
        id
        translations {
          id
          language
          name
        }
      }
      layerCounts {
        id
        translations {
          id
          language
          name
        }
      }
    }
  }
`;

export const useGetProducts = () => {
  const { data, loading, error, refetch } = useQuery(GET_PRODUCTS);

  return {
    products: data ? data.products : [],
    loading,
    error,
    refetch,
  };
};
