import { Spin } from "antd";
import { Navigate } from "react-router-dom";
import userStore from "../../store/userStore";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, loading } = userStore();

  if (loading) return <Spin />;
  if (!isAuthenticated) return <Navigate to="/login" />;
  return <>{children}</>;
};
