import { gql } from "@apollo/client";
import { useMutation, ApolloError } from "@apollo/client";

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: String!) {
    deleteOneProduct(where: { id: $id }) {
      id
    }
  }
`;

// export const useDeleteProductMutation = () => {
//   return useMutation(DELETE_PRODUCT);
// };

export const useDeleteProductMutation = (refetchProducts: () => void) => {
  const [deleteProduct, { loading }] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      refetchProducts();
    },
  });

  const handleDeleteProduct = async (id: string) => {
    try {
      const result = await deleteProduct({
        variables: { id },
      });
      return { success: true, data: result.data };
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(
          "Apollo Error:",
          error.message,
          error.graphQLErrors,
          error.networkError
        );
      } else {
        console.error("Unknown error:", error);
      }
      return { success: false, error };
    }
  };

  return { handleDeleteProduct, loading };
};
