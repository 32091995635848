import { gql, useMutation, ApolloError } from "@apollo/client";

export const DELETE_ONE_CONTENT = gql`
  mutation DeleteOnePost($id: String!) {
    deleteOnePost(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteContent = (refetchContents: () => void) => {
  const [deleteContent, { loading }] = useMutation(DELETE_ONE_CONTENT, {
    onCompleted: () => {
      refetchContents();
    },
  });

  const handleDeleteContent = async (id: string) => {
    try {
      const result = await deleteContent({
        variables: { id },
      });
      return { success: true, data: result.data };
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(
          "Apollo Error:",
          error.message,
          error.graphQLErrors,
          error.networkError
        );
      } else {
        console.error("Unknown error:", error);
      }
      return { success: false, error };
    }
  };

  return { handleDeleteContent, loading };
};
