import { create } from "zustand";
import dayjs from "dayjs";
import { axiosInstance } from "../utils/axiosConfig";

export type UserStore = {
  accessToken: {
    token: string;
    expiresIn: Date;
  } | null;
  user: User | null;
  isAuthenticated: boolean;
  login: (
    token: {
      token: string;
      expiresIn: Date;
    },
    user: UserStore["user"]
  ) => void;
  logout: () => void;
  loading: boolean;
};

export type User = {
  id: string;
  email: string;
  role: boolean;
};

const userStore = create<UserStore>((set) => {
  const storedToken = JSON.parse(localStorage.getItem("accessToken") || "{}");
  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

  const isExpired = Object.keys(storedToken).length
    ? dayjs(storedToken.expiresIn).isBefore(dayjs())
    : true;

  const initialState = {
    accessToken: isExpired
      ? null
      : Object.keys(storedToken).length
      ? {
          token: storedToken.token,
          expiresIn: new Date(storedToken.expiresIn),
        }
      : null,
    user: isExpired ? null : Object.keys(storedUser).length ? storedUser : null,
    isAuthenticated: !isExpired,
    loading: false,
  };

  const getUser = async () => {
    try {
      set(() => ({ loading: true }));
      initialState.loading = true;
      const { data } = await axiosInstance.get("/admin-auth/me");
      set(() => ({ user: data, isAuthenticated: true }));
      initialState.user = data;
      initialState.isAuthenticated = true;
    } catch (error) {
      set(() => ({ user: null, isAuthenticated: false, accessToken: null }));
      initialState.user = null;
      initialState.isAuthenticated = false;
    } finally {
      set(() => ({ loading: false }));
      initialState.loading = false;
    }
  };

  if (storedToken && !isExpired) {
    getUser();
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
  }

  return {
    ...initialState,
    login: (accessToken, user) => {
      set((state: UserStore) => {
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
        localStorage.setItem("user", JSON.stringify(user));

        return {
          ...state,
          accessToken: {
            token: accessToken.token,
            expiresIn: accessToken.expiresIn,
          },
          user,
          isAuthenticated: true,
        };
      });
    },
    logout: () => {
      set(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        return {
          accessToken: null,
          user: null,
          isAuthenticated: false,
        };
      });
    },
  };
});

export default userStore;
