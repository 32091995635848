import { gql, useQuery } from "@apollo/client";

const FIND_SPECIE = gql`
  query FindUniqueSpecies($where: SpeciesWhereUniqueInput!) {
    findUniqueSpecies(where: $where) {
      translations {
        name
        language
      }
    }
  }
`;

export const useFindOneSpecie = (id: string) => {
  const { data, loading, error, refetch } = useQuery(FIND_SPECIE, {
    variables: { where: { id } },
  });

  return {
    data: data?.findUniqueSpecies || [],
    loading,
    error,
    refetch,
  };
};
