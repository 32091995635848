import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const UPDATE_ONE_PACKAGE = gql`
  mutation UpdatePackageSize(
    $data: PackageSizeUpdateInput!
    $where: PackageSizeWhereUniqueInput!
  ) {
    updateOnePackageSize(data: $data, where: $where) {
      translations {
        name
        language
      }
    }
  }
`;

export const useUpdatePackage = (refetchAttributes: () => void) => {
  const [updatePackages, { loading, error, data }] = useMutation<{ data: any }>(
    UPDATE_ONE_PACKAGE,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { updatePackages, loading, error, data };
};
