import { gql, useMutation } from "@apollo/client";

export const DELETE_ATTRIBUTE = gql`
  mutation DeleteProductAttribute($id: String!) {
    deleteOneProductAttribute(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteProductAttribute = (onCompleted?: () => void) => {
  const [deleteProductAttribute, { data, loading, error }] = useMutation(
    DELETE_ATTRIBUTE,
    {
      onCompleted,
    }
  );

  const handleDeleteAttribute = async (id: string) => {
    try {
      const result = await deleteProductAttribute({ variables: { id } });
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeleteAttribute,
    data,
    loading,
    error,
  };
};
