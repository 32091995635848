import { ReactNode } from "react";
import { Spin } from "antd";
import { Navigate } from "react-router-dom";
import userStore from "../../store/userStore";

export const UnprotectedRoute = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, loading } = userStore();

  if (loading) return <Spin />;
  if (isAuthenticated) return <Navigate to="/" />;
  return <>{children}</>;
};
