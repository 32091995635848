export enum ProductLanguage {
  EN = "EN",
  RU = "RU",
  KA = "KA",
}

export const languages: { code: LanguageCode; name: string }[] = [
  { code: "EN", name: "English" },
  { code: "RU", name: "Russian" },
  { code: "KA", name: "Georgian" },
];

export interface ProductAttributeTranslationInput {
  language: ProductLanguage;
  name: string;
}

export interface ProductAttributeCreateInput {
  imageUrl: string;
  translations: ProductAttributeTranslationInput[];
}

export interface ProductAttributeTranslation {
  language: "EN" | "RU" | "KA";

  id: string;
  name: string;
}

export interface CreateProductAttributeResponse {
  createProductAttribute: {
    id: string;
    imageUrl: string;
    translations: ProductAttributeTranslation[];
  };
}

export interface UpdateAttributeInput {
  imageUrl?: string;
  translations?: {
    update?: {
      language: string;
      name: string;
    }[];
  };
}

export interface UpdateAttributeData {
  updateProductAttribute: {
    id: string;
    imageUrl: string;
    translations: {
      id?: string;
      language: string;
      name: string;
    }[];
  };
}

export interface UpdateAttributeVars {
  id: string;
  data: UpdateAttributeInput;
}

export interface ProductAttributeTranslation {
  id: string;
  languages: LanguageCode;
  name: string;
}

export interface ProductAttributeUpdateInput {
  imageUrl?: { set: string[] };
  translations?: {
    update: Array<{
      where: { id: string };
      data: {
        name: { set: string };
      };
    }>;
  };
}
export type LanguageCode = "EN" | "KA" | "RU";

export interface ProductAttribute {
  id: string;
  imageUrl: string[];
  translations: ProductAttributeTranslation[];
}
export interface UpdateProductAttributeMutationResponse {
  updateOneProductAttribute: ProductAttribute;
}

//product

export interface ProductCreateInput {
  code: string;
  mainImageUrl: string;
  additionalImages: { set: string[] };
  translations: {
    create: Array<{
      language: ProductLanguage;
      name: string;
      description: string;
    }>;
  };
  attributes?: {
    connect: Array<{ id: string }>;
  };
  species?: {
    connect: Array<{ id: string }>;
  };
  packageSizes?: {
    connect: Array<{ id: string }>;
  };
  layerCounts?: {
    connect: Array<{ id: string }>;
  };
}

export interface ProductTranslation {
  id: string;
  language: ProductLanguage;
  name: string;
  description: string;
}

export interface AttributeTranslation {
  id: string;
  language: ProductLanguage;
  name: string;
}

export interface CreateProductResponse {
  createOneProduct: {
    id: string;
    code: string;
    mainImageUrl: string;
    additionalImages: string[];
    translations: ProductTranslation[];
    attributes: ProductAttribute[];
    species: AttributeTranslation[];
    packageSizes: AttributeTranslation[];
    layerCounts: AttributeTranslation[];
  };
}
//edit
// types/product/productTypes.ts

export interface ProductTranslationInput {
  id: string;
  name: { set: string };
  description: { set: string };
}

export interface ProductUpdateInput {
  mainImageUrl?: { set: string };
  additionalImages?: { set: string[] };
  translations?: {
    upsert: ProductTranslationInput[];
  };
}

export interface UpdateProductVars {
  id: string;
  data: ProductUpdateInput;
}

export interface ProductTranslationData {
  id: string;
  language: "EN" | "RU" | "KA";
  name: string;
  description: string;
}

export interface UpdateProductData {
  updateOneProduct: {
    id: string;
    mainImageUrl: string;
    additionalImages: string[];
    attributes: { id: string }[];
    translations: ProductTranslationData[];
  };
}
