import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const CREATE_ONE_SPECIES = gql`
  mutation CreateSpecies($data: SpeciesCreateInput!) {
    createOneSpecies(data: $data) {
      translations {
        name
        language
      }
    }
  }
`;

export const useCreateSpecies = (refetchAttributes: () => void) => {
  const [createSpecies, { loading, error, data }] = useMutation<{ data: any }>(
    CREATE_ONE_SPECIES,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { createSpecies, loading, error, data };
};
