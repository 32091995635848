import { ConfigProvider, Layout } from "antd";
import SideBar from "./SideBar";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useState } from "react";
const { Content } = Layout;
export const CustomLayout = () => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("collapsed") === "true"
  );
  const onCollapse = (collapsed: boolean) => {
    localStorage.setItem("collapsed", collapsed.toString());
    setCollapsed(collapsed);
  };
  return (
    <ConfigProvider>
      <StyledLayout>
        <SideBar onCollapse={onCollapse} collapsed={collapsed} />
        <StyledLayout>
          <StyledContent $collapsed={collapsed}>
            <Outlet />
          </StyledContent>
        </StyledLayout>
      </StyledLayout>
    </ConfigProvider>
  );
};
const StyledLayout = styled.div`
  min-height: 100vh;
  background-color: #fff;
  width: 100vw;
  .ant-layout-sider {
    background-color: #fff !important;
  }
`;
const StyledContent = styled(Content)<{ $collapsed: boolean }>`
  text-align: center;
  min-height: 100vh;
  overflow: auto;
  margin-left: ${(props) => (props.$collapsed ? "80px" : "250px")};
`;
