import { useQuery, gql } from "@apollo/client";

const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: String!) {
    product(where: { id: $id }) {
      id
      mainImageUrl
      additionalImages
      translations {
        id
        language
        name
        description
      }
      attributes {
        imageUrl
        id
      }
      species {
        id
      }
      packageSizes {
        id
      }
      layerCounts {
        id
      }
    }
  }
`;

export const useGetProductById = (id: string | undefined) => {
  const { data, loading, error } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id },
    skip: !id,
  });

  return {
    data: data?.product,
    loading,
    error,
  };
};
