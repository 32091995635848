const languages = [
  {
    code: "KA",
    name: "🇬🇪 ქართული",
  },
  {
    code: "EN",
    name: "🇺🇸 ინგლისური",
  },
  {
    code: "RU",
    name: "🇷🇺 რუსული",
  },
];

export default languages;
