import { gql, useMutation } from "@apollo/client";

export const DELETE_LAYER = gql`
  mutation DeleteLayerCount($id: String!) {
    deleteOneLayerCount(where: { id: $id }) {
      id
    }
  }
`;

export const useDeleteLayer = (onCompleted?: () => void) => {
  const [deleteLayer, { data, loading, error }] = useMutation(DELETE_LAYER, {
    onCompleted,
  });

  const handleDeleteLayer = async (id: string) => {
    try {
      const result = await deleteLayer({ variables: { id } });
      return { success: true, data: result.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  return {
    handleDeleteLayer,
    data,
    loading,
    error,
  };
};
