import { gql, useMutation } from "@apollo/client";
import {
  ContentUpdateInput,
  UpdateOneContentResponse,
} from "../../../types/blog/blogTypes";

// GraphQL mutation for updating content
const UPDATE_ONE_CONTENT = gql`
  mutation UpdateOnePost($id: String!, $data: PostUpdateInput!) {
    updateOnePost(where: { id: $id }, data: $data) {
      id
      index
      imageUrl
      titles {
        id
        language
        title
      }
      components {
        id
        type
        images {
          id
          url
        }
        translations {
          id
          language
          subtitle
          contentText
        }
      }
    }
  }
`;

export const useUpdateContent = (refetchContents: () => void) => {
  const [updateContent, { loading, error, data }] = useMutation<
    UpdateOneContentResponse,
    { id: string; data: ContentUpdateInput }
  >(UPDATE_ONE_CONTENT, {
    onCompleted: () => {
      // Call the refetch function after successful mutation
      refetchContents();
    },
  });

  return { updateContent, loading, error, data };
};
