import { gql, useMutation } from "@apollo/client";
import {
  UpdateOneAboutUsResponse,
  AboutUsUpdateInput,
} from "../../../types/about-us/aboutUsTypes";

const UPDATE_ONE_ABOUT_US = gql`
  mutation UpdateOneAboutUs($id: String!, $data: AboutUsUpdateInput!) {
    updateOneAboutUs(where: { id: $id }, data: $data) {
      id
      imageUrl
      user
      magazine
      firstYear
      lastYear
      existenceYear
      translations {
        language
        title
        description
        mainImage
      }
    }
  }
`;

export const useUpdateAboutUs = (refetchAboutUs: () => void) => {
  const [updateAboutUs, { loading, error, data }] = useMutation<
    UpdateOneAboutUsResponse,
    { id: string; data: AboutUsUpdateInput }
  >(UPDATE_ONE_ABOUT_US, {
    onCompleted: () => {
      refetchAboutUs();
    },
  });

  return { updateAboutUs, loading, error, data };
};
