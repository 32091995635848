import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import {
  ProductAttributeCreateInput,
  CreateProductAttributeResponse,
} from "../../../../types/product/productTypes";

export const CREATE_PRODUCT_ATTRIBUTE = gql`
  mutation CreateOneProductAttribute($data: ProductAttributeCreateInput!) {
    createOneProductAttribute(data: $data) {
      id
      imageUrl
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useCreateProductAttribute = (refetchAttributes: () => void) => {
  const [createProductAttribute, { loading, error, data }] = useMutation<
    CreateProductAttributeResponse,
    { data: ProductAttributeCreateInput }
  >(CREATE_PRODUCT_ATTRIBUTE, {
    onCompleted: () => {
      refetchAttributes();
    },
  });

  return { createProductAttribute, loading, error, data };
};
