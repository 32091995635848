import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const UPDATE_ONE_ORDER = gql`
  mutation UpdateOneWhoWeAre(
    $data: WhoWeAreUpdateInput!
    $where: WhoWeAreWhereUniqueInput!
  ) {
    updateOneWhoWeAre(where: $where, data: $data) {
      id
      imageUrl
      videoUrl
      enDescription
      kaDescription
      ruDescription
      productAttributes {
        id
      }
    }
  }
`;

export const useUpdateOrder = (refetchAttributes: () => void) => {
  const [updateOrders, { loading, error, data }] = useMutation<{ data: any }>(
    UPDATE_ONE_ORDER,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { updateOrders, loading, error, data };
};
