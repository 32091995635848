import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message, Select, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCreateProduct } from "../../../graphql/mutations/Product/Product/createProduct";
import { useGetProducts } from "../../../graphql/queries/Products/Products/getProducts";
import { ProductLanguage } from "../../../types/product/productTypes";
import { useGetAllAttribute } from "../../../graphql/queries/Products/Attributes/getAttribute";
import { useNavigate } from "react-router-dom";
import { useGetAllSpecies } from "../../../graphql/queries/Filter/Species/getSpecies";
import { useGetAllPackages } from "../../../graphql/queries/Filter/Packages/getPackages";
import { useGetAllLayers } from "../../../graphql/queries/Filter/Layer/getLayers";

const { Text } = Typography;

const CreateProductComponent = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { createProduct, loading, error } = useCreateProduct(() => {
    navigate("/products");
  });
  const { products, refetch } = useGetProducts();
  const { data: attributeData } = useGetAllAttribute();
  const { data: speciesData } = useGetAllSpecies();
  const { data: packageSizeData } = useGetAllPackages();
  const { data: layerCountData } = useGetAllLayers();
  const [newCode, setNewCode] = useState<string>("");
  const [mainImage, setMainImage] = useState<string>("");
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [translations, setTranslations] = useState({
    [ProductLanguage.EN]: { name: "", description: "" },
    [ProductLanguage.RU]: { name: "", description: "" },
    [ProductLanguage.KA]: { name: "", description: "" },
  });

  useEffect(() => {
    const findNewCode = () => {
      if (products && products.length > 0) {
        const highestCode = Math.max(
          ...products.map((product: any) => parseInt(product.code, 10))
        );
        setNewCode((highestCode + 1).toString().padStart(4, "0"));
      } else {
        setNewCode("0001");
      }
    };

    findNewCode();
  }, [products]);

  const handleCreateProduct = (values: any) => {
    const { attributes, species, packageSizes, layerCounts } = values;

    createProduct({
      variables: {
        data: {
          code: newCode,
          mainImageUrl: mainImage,
          additionalImages: { set: additionalImages },
          translations: {
            create: [
              {
                language: ProductLanguage.EN,
                name: translations[ProductLanguage.EN].name,
                description: translations[ProductLanguage.EN].description,
              },
              {
                language: ProductLanguage.RU,
                name: translations[ProductLanguage.RU].name,
                description: translations[ProductLanguage.RU].description,
              },
              {
                language: ProductLanguage.KA,
                name: translations[ProductLanguage.KA].name,
                description: translations[ProductLanguage.KA].description,
              },
            ],
          },
          attributes: {
            connect: attributes?.map((id: string) => ({ id })),
          },
          species: {
            connect: species?.map((id: string) => ({ id })),
          },
          packageSizes: {
            connect: packageSizes?.map((id: string) => ({ id })),
          },
          layerCounts: {
            connect: layerCounts?.map((id: string) => ({ id })),
          },
        },
      },
    })
      .then(() => {
        message.success("პროდუქტი წარმატებით დაემატა");
        refetch();
      })
      .catch((error) => {
        message.error("Error creating product");
        console.error(error);
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const uploadMainImageProps = {
    beforeUpload: (file: any) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      try {
        const uploadUrl = `${process.env.REACT_APP_API_URL}/upload`;

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        const imageUrl = responseData.url;

        setMainImage(imageUrl);
        form.setFieldsValue({ mainImageUrl: imageUrl });

        onSuccess();
      } catch (error) {
        console.error("Upload error:", error);
        onError(error);
      }
    },
  };

  const uploadAdditionalImagesProps = {
    beforeUpload: (file: any) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      try {
        const uploadUrl = `${process.env.REACT_APP_API_URL}/upload`;

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        const imageUrl = responseData.url;

        setAdditionalImages((prevImages) => [...prevImages, imageUrl]);

        onSuccess();
      } catch (error) {
        console.error("Upload error:", error);
        onError(error);
      }
    },
  };

  return (
    <Form
      form={form}
      onFinish={handleCreateProduct}
      layout="vertical"
      style={{ margin: "30px" }}
    >
      <Form.Item
        label="მთავარი სურათი"
        name="mainImageUrl"
        rules={[
          { required: true, message: "მთავარი სურათის ატვირთვა სავალდებულოა" },
        ]}
      >
        <Upload {...uploadMainImageProps} showUploadList={false}>
          <Button icon={<UploadOutlined />}>ატვირთეთ მთავარი სურათი</Button>
        </Upload>
        {mainImage && (
          <img
            src={`${process.env.REACT_APP_API_URL}/uploads/${mainImage}`}
            alt="Main"
            style={{ width: 100, height: 100, marginTop: 10 }}
          />
        )}
      </Form.Item>

      <Form.Item label="პროდუქტის სურათები">
        <Upload {...uploadAdditionalImagesProps} listType="picture">
          <Button icon={<UploadOutlined />}>ატვირთეთ სურათები</Button>
        </Upload>
        <div>
          {additionalImages.map((url, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_API_URL}/uploads/${url}`}
              alt={`Additional ${index}`}
              style={{ width: 100, height: 100, marginTop: 10 }}
            />
          ))}
        </div>
      </Form.Item>

      <Form.Item
        label="პროდუქტის სახელი (EN)"
        name={[ProductLanguage.EN, "name"]}
        rules={[
          { required: true, message: "გთხოვთ შეიყვანოთ პროდუქტის სახელი" },
        ]}
      >
        <Input
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.EN]: {
                ...prev[ProductLanguage.EN],
                name: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item
        label="პროდუქტის აღწერა (EN)"
        name={["translations", ProductLanguage.EN, "description"]}
        rules={[{ message: "გთხოვთ შეიყვანოთ პროდუქტის აღწერა" }]}
      >
        <Input.TextArea
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.EN]: {
                ...prev[ProductLanguage.EN],
                description: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item
        label="პროდუქტის სახელი (RU)"
        name={["translations", ProductLanguage.RU, "name"]}
        rules={[
          { required: true, message: "გთხოვთ შეიყვანოთ პროდუქტის სახელი" },
        ]}
      >
        <Input
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.RU]: {
                ...prev[ProductLanguage.RU],
                name: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item
        label="პროდუქტის აღწერა (RU)"
        name={["translations", ProductLanguage.RU, "description"]}
      >
        <Input.TextArea
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.RU]: {
                ...prev[ProductLanguage.RU],
                description: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item
        label="პროდუქტის სახელი (KA)"
        name={["translations", ProductLanguage.KA, "name"]}
        rules={[
          { required: true, message: "გთხოვთ შეიყვანოთ პროდუქტის სახელი" },
        ]}
      >
        <Input
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.KA]: {
                ...prev[ProductLanguage.KA],
                name: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item
        label="პროდუქტის აღწერა (KA)"
        name={["translations", ProductLanguage.KA, "description"]}
        rules={[{ message: "გთხოვთ შეიყვანოთ პროდუქტის აღწერა" }]}
      >
        <Input.TextArea
          onChange={(e) =>
            setTranslations((prev) => ({
              ...prev,
              [ProductLanguage.KA]: {
                ...prev[ProductLanguage.KA],
                description: e.target.value,
              },
            }))
          }
        />
      </Form.Item>
      <Form.Item label="ატრიბუტები" name="attributes">
        <Select
          mode="multiple"
          placeholder="აირჩიე ატრიბუტები"
          style={{ width: "100%" }}
        >
          {attributeData.map((attribute: any) => (
            <Select.Option key={attribute.id} value={attribute.id}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${attribute.imageUrl}`}
                  alt={attribute?.name}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
                <Text>{attribute.translations[0]?.name}</Text>
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="სახეობები"
        name="species"
        rules={[{ required: true, message: "გთხოვთ აირჩიოთ სახეობები" }]}
      >
        <Select
          mode="multiple"
          placeholder="აირჩიე სახეობები"
          style={{ width: "100%" }}
        >
          {speciesData.map((species: any) => (
            <Select.Option key={species.id} value={species.id}>
              <Text>{species.translations[0].name}</Text>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="რაოდენობა შეკვრაში" name="packageSizes">
        <Select
          mode="multiple"
          placeholder="აირჩიე რაოდენობა შეკვრაში"
          style={{ width: "100%" }}
        >
          {packageSizeData.map((packageSize: any) => (
            <Select.Option key={packageSize.id} value={packageSize.id}>
              <Text>{packageSize.translations[0].name}</Text>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="ფენების რაოდენობა" name="layerCounts">
        <Select
          mode="multiple"
          placeholder="აირჩიე ფენების რაოდენობა"
          style={{ width: "100%" }}
        >
          {layerCountData.map((layerCount: any) => (
            <Select.Option key={layerCount.id} value={layerCount.id}>
              <Text>{layerCount.translations[0].name}</Text>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          შექმენით პროდუქტი
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProductComponent;
