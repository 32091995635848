import { gql, useQuery } from "@apollo/client";

const GET_ALL_PACKAGES = gql`
  query MyQuery {
    packageSizes {
      id
      translations {
        id
        name
        language
      }
    }
  }
`;

export const useGetAllPackages = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_PACKAGES);

  return {
    data: data?.packageSizes || [],
    loading,
    error,
    refetch,
  };
};
