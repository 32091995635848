import { Button, message, Popconfirm, Table } from "antd";
import Title from "antd/es/typography/Title";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosConfig";
import { admins } from "../../data/admins";

const Accounts = () => {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get("/users");
      setData(data.users);
    } catch (error) {
      message.error("მონაცემების მოპოვებისას შეცდომა");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title: "სათაური",
          key: "accounts title",
          render: (_, record: any) => (
            <div className="flex flex-col gap-2">
              <p>{record.email}</p>
            </div>
          ),
        },
        {
          title: "ქმედება",
          key: "accounts action",
          render: (_: any, record: any, index: number) => (
            <Button.Group>
              <Link to={`/accounts/edit/${record.id}`}>
                <Button type="primary">რედაქტირება</Button>
              </Link>
              <Popconfirm
                title="გსურთ წაშლა?"
                onConfirm={async () => {
                  try {
                    const { status } = await axiosInstance.delete(
                      `/users/delete/${record.id}`
                    );
                    if (status === 200) {
                      message.success("სიახლე წაიშალა");
                      fetchData();
                    } else {
                      message.error("შეცდომა წაშლისას: უცნობი შეცდომა");
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
                okText="კი"
                cancelText="არა"
              >
                {!admins.includes(record.email) && (
                  <Button type="primary" danger>
                    წაშლა
                  </Button>
                )}
              </Popconfirm>
            </Button.Group>
          ),
        },
      ]}
      dataSource={data || []}
      title={() => (
        <div>
          <div className="BlogTitle-addButton">
            <Title level={3}>ექაუნთები</Title>
            <Link to={"/accounts/create"}>
              <Button type="primary">დამატება</Button>
            </Link>
          </div>
        </div>
      )}
      tableLayout="fixed"
      bordered
    />
  );
};

export default Accounts;
