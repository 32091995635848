import { gql, useQuery } from "@apollo/client";
import { UpdateOneAboutUsResponse } from "../../../types/about-us/aboutUsTypes";

export const GET_ONE_ABOUT_US = gql`
  query GetOneAboutUs($id: String!) {
    aboutUs(where: { id: $id }) {
      id
      imageUrl
      user
      magazine
      existenceYear
      firstYear
      lastYear
      translations {
        id
        language
        title
        description
        mainImage
      }
    }
  }
`;

export const useGetOneAboutUs = (id: string) => {
  const { data, loading, error, refetch } = useQuery<UpdateOneAboutUsResponse>(
    GET_ONE_ABOUT_US,
    {
      variables: { id },
      skip: !id,
    }
  );

  return { data, loading, error, refetch };
};
