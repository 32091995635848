import { gql, useMutation } from "@apollo/client";

export const UPDATE_GALLERY_IMAGE = gql`
  mutation UpdateOneGalleryImage(
    $id: String!
    $data: GalleryImageUpdateInput!
  ) {
    updateOneGalleryImage(where: { id: $id }, data: $data) {
      id
      url
      mobileIndex
      desktopIndex
      createdAt
      updatedAt
    }
  }
`;

export const useUpdateGalleryImage = (onCompleted?: () => void) => {
  const [updateGalleryImage, { data, loading, error }] = useMutation(
    UPDATE_GALLERY_IMAGE,
    {
      onCompleted,
    }
  );

  return {
    updateGalleryImage,
    data,
    loading,
    error,
  };
};
