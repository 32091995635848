import { gql, useQuery } from "@apollo/client";

export const GET_ATTRIBUTE_BY_ID = gql`
  query GetAttributeById($id: String!) {
    productAttribute(where: { id: $id }) {
      id
      imageUrl
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useGetOneAttribute = (id: string | any) => {
  const { data, loading, error } = useQuery(GET_ATTRIBUTE_BY_ID, {
    variables: { id },
    skip: !id,
  });

  return {
    data,
    loading,
    error,
  };
};
