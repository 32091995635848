import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, message } from "antd";
import CREATE_HERO from "../../../graphql/mutations/Hero/createHero";
import languages from "../../../constants/languages";
import { useNavigate } from "react-router-dom";
import GET_ALL_HEROES from "../../../graphql/queries/Hero/getAllHeros";

const CreateHero = () => {
  const [form] = Form.useForm();
  const { refetch } = useQuery(GET_ALL_HEROES);
  const [createHero] = useMutation(CREATE_HERO, {
    onCompleted: () => {
      refetch();
    },
  });
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const featureCount = 3;

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = {
      name: {
        create: languages.map((lang) => ({
          language: lang.code.toLowerCase(),
          text: values[`title_${lang.code.toLowerCase()}`],
        })),
      },
      description: {
        create: languages.map((lang) => ({
          language: lang.code.toLowerCase(),
          text: values[`description_${lang.code.toLowerCase()}`],
        })),
      },
      features: {
        create: Array.from({ length: featureCount }).map((_, i) => ({
          titles: {
            create: languages.map((lang) => ({
              language: lang.code.toLowerCase(),
              text: values[`feature_${i + 1}_title_${lang.code.toLowerCase()}`],
            })),
          },
          descriptions: {
            create: languages.map((lang) => ({
              language: lang.code.toLowerCase(),
              text: values[
                `feature_${i + 1}_description_${lang.code.toLowerCase()}`
              ],
            })),
          },
        })),
      },
    };

    try {
      await createHero({
        variables: {
          data,
        },
      });
      message.success("Hero created successfully!");
      navigation("/hero");
    } catch (error: any) {
      console.error("Error creating hero:", error);
      message.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "30px" }}
      >
        <h2 style={{ margin: "10px 0" }}>შესავლის ინფორმაციის დამატება</h2>

        {/* Hero Title Fields */}
        {languages.map((lang) => (
          <Form.Item
            key={`title_${lang.code}`}
            label={`სათაური (${lang.name})`}
            name={`title_${lang.code.toLowerCase()}`}
            rules={[
              {
                required: true,
                message: `გთხოვთ სათაური შეიყვანოთ ${lang.name}-ად`,
              },
            ]}
          >
            <Input placeholder="სათაური" />
          </Form.Item>
        ))}

        {/* Hero Description Fields */}
        {languages.map((lang) => (
          <Form.Item
            key={`description_${lang.code}`}
            label={`აღწერა (${lang.name})`}
            name={`description_${lang.code.toLowerCase()}`}
            rules={[
              {
                required: true,
                message: `გთხოვთ აღწერა შეიყვანოთ ${lang.name}-ად`,
              },
            ]}
          >
            <Input.TextArea placeholder="აღწერა" />
          </Form.Item>
        ))}

        <h2 style={{ margin: "10px 0" }}>მახასიათებლები</h2>

        {/* Dynamic Feature Title and Description Fields */}
        {Array.from({ length: featureCount }).map((_, i) => (
          <div key={`feature_${i + 1}`}>
            <h3>მახასიათებელი {i + 1}</h3>

            {/* Feature Title Fields */}
            {languages.map((lang) => (
              <Form.Item
                key={`feature_${i + 1}_title_${lang.code}`}
                label={`მახასიათებლის სათაური (${lang.name})`}
                name={`feature_${i + 1}_title_${lang.code.toLowerCase()}`}
                rules={[
                  {
                    required: true,
                    message: `გთხოვთ მახასიათებლის სათაური შეიყვანოთ ${lang.name}-ად`,
                  },
                ]}
              >
                <Input placeholder="მახასიათებლის სათაური" />
              </Form.Item>
            ))}

            {/* Feature Description Fields */}
            {languages.map((lang) => (
              <Form.Item
                key={`feature_${i + 1}_description_${lang.code}`}
                label={`მახასიათებლის აღწერა (${lang.name})`}
                name={`feature_${i + 1}_description_${lang.code.toLowerCase()}`}
                rules={[
                  {
                    required: true,
                    message: `გთხოვთ მახასიათებლის აღწერა შეიყვანოთ ${lang.name}-ად`,
                  },
                ]}
              >
                <Input.TextArea placeholder="მახასიათებლის აღწერა" />
              </Form.Item>
            ))}
          </div>
        ))}

        <Button type="primary" htmlType="submit" loading={loading}>
          შენახვა
        </Button>
      </Form>
    </section>
  );
};

export default CreateHero;
