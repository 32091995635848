import { gql, useQuery } from "@apollo/client";

const FIND_PACKAGE = gql`
  query PackageSize($where: PackageSizeWhereUniqueInput!) {
    packageSize(where: $where) {
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useFindPackage = (id: string) => {
  const { data, loading, error, refetch } = useQuery(FIND_PACKAGE, {
    variables: { where: { id } },
  });

  return {
    data: data?.packageSize || [],
    loading,
    error,
    refetch,
  };
};
