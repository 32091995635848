import { gql, useQuery } from "@apollo/client";

const GET_ALL_ABOUT_US = gql`
  query GetAllAboutUs {
    aboutuses {
      id
      user
      magazine
      existenceYear
      imageUrl
      firstYear
      lastYear
      translations {
        id
        language
        title
        description
        mainImage
      }
    }
  }
`;

export const useGetAllAboutUs = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_ABOUT_US);

  return {
    data: data?.aboutuses || [],
    loading,
    error,
    refetch,
  };
};
