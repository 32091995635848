import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status) => status < 500,
});

const token = localStorage.getItem("accessToken")
  ? JSON.parse(localStorage.getItem("accessToken") as string).token
  : "";

axiosInstance.defaults.headers.common["Authorization"] = token
  ? `Bearer ${token}`
  : "";
