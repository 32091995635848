import { gql, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";

export const UPDATE_GALLERY_VIDEO = gql`
  mutation UpdateOneGalleryVideo(
    $id: String!
    $data: GalleryVideoUpdateInput!
  ) {
    updateOneGalleryVideo(where: { id: $id }, data: $data) {
      id
      url
      thumbnailUrl
      createdAt
      updatedAt
    }
  }
`;

export const useUpdateGalleryVideo = (onCompleted: () => void) => {
  const [updateGalleryVideo, { loading }] = useMutation(UPDATE_GALLERY_VIDEO, {
    onCompleted,
    onError: (error: ApolloError) => {
      console.error("Error updating gallery video:", error.message);
    },
  });

  return {
    updateGalleryVideo,
    loading,
  };
};
