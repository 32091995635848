import React from "react";
import { Card, Col, Row, Spin, Typography, Alert, Button } from "antd";
import { useGetAllAboutUs } from "../graphql/queries/About-us/getAboutUs";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

const AboutUs: React.FC = () => {
  const { data, loading, error } = useGetAllAboutUs();

  if (loading) return <Spin tip="Loading..." />;
  if (error)
    return <Alert message="Error" description={error.message} type="error" />;

  return (
    <div style={{ padding: 20 }}>
      <Title level={2}>კომპანიის შესახებ ინფორმაცია</Title>
      <div style={{ marginTop: 20, textAlign: "center" }}>
        <Link to="/about-us/create">
          <Button
            type="primary"
            disabled={data.length >= 3}
            style={{ marginBottom: 20 }}
          >
            ახლის დამატება
          </Button>
        </Link>
      </div>
      <Row gutter={[16, 16]}>
        {data.slice(0, 3).map((aboutUs: any) => (
          <Col xs={24} sm={24} md={8} key={aboutUs.id}>
            <Card
              bordered={false}
              extra={
                <Link to={`/about-us/edit/${aboutUs.id}`}>
                  <Button type="primary">რედაქტირება</Button>
                </Link>
              }
              style={{ border: "1px solid #d9d9d9", borderRadius: 8 }}
            >
              <div style={{ marginBottom: 16 }}>
                {aboutUs.imageUrl.map((url: string, index: number) => (
                  <img
                    key={index}
                    src={`${process.env.REACT_APP_API_URL}/uploads/${url}`}
                    alt={`About Us ${index}`}
                    style={{
                      width: "30%",
                      maxHeight: 150,
                      objectFit: "cover",
                      marginBottom: 10,
                    }}
                  />
                ))}
              </div>
              {aboutUs.translations.map((translation: any) => (
                <div key={translation.id} style={{ marginBottom: 10 }}>
                  <Title level={4}>{translation.language}</Title>
                  <Paragraph strong>{translation.title}</Paragraph>
                  <Paragraph>{translation.description}</Paragraph>
                </div>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AboutUs;
