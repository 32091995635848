import { Spin, List, Card, Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useGetAllGalleryImages } from "../../../graphql/queries/gallery/Gallery-image/getImage";
import { useDeleteGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/deleteImage";
import { GalleryImages } from "../../../types/gallery/videoTypes";

const ImageList = () => {
  const { data, loading, error, refetch } = useGetAllGalleryImages();
  const { handleDeleteImage, loading: deleteLoading } =
    useDeleteGalleryImage(refetch);

  const handleDelete = async (imageId: string) => {
    const result = await handleDeleteImage(imageId);
    if (result.success) {
      console.log(`Deleted image with ID: ${imageId}`);
    } else {
      console.error("Failed to delete image:", result.error);
    }
  };

  if (loading) return <Spin />;
  if (error) return <p>Error loading images.</p>;

  return (
    <div style={{ padding: "20px" }}>
      <Link to="/gallery/image/create">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "40px" }}
        >
          სურათის დამატება
        </Button>
      </Link>

      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        }}
        dataSource={data}
        renderItem={(item: GalleryImages) => (
          <List.Item style={{ marginBottom: "16px" }}>
            <Card
              cover={
                <img
                  alt="gallery"
                  src={`${process.env.REACT_APP_API_URL}/uploads/${item.url}`}
                  style={{
                    width: "200px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              }
              actions={[
                <Link to={`/gallery/image/edit/${item.id}`} key="edit">
                  <Button
                    size="small"
                    icon={<EditOutlined />}
                    style={{ background: "rgba(255, 255, 255, 0.7)" }}
                  />
                </Link>,
                <Popconfirm
                  title="დარწმუნებული ხარ რომ წაშლა გინდა?"
                  onConfirm={() => handleDelete(item.id)}
                  okText="კი"
                  cancelText="არა"
                  key="delete"
                >
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    style={{ background: "rgba(255, 255, 255, 0.7)" }}
                    loading={deleteLoading}
                  />
                </Popconfirm>,
              ]}
            >
              <Card.Meta
                title={`მობ: ${item.mobileIndex}, დესკ: ${item.desktopIndex}`}
              />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ImageList;
