import { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  Card,
  message,
  Upload,
  Select,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UploadChangeParam } from "antd/es/upload/interface";
import languages from "../../../constants/languages";
import { useGetOneAttribute } from "../../../graphql/queries/Products/Attributes/findAttribute";
import { useUpdateAttribute } from "../../../graphql/mutations/Product/Attribute/updateAttribute";
import { LanguageCode } from "../../../types/about-us/aboutUsTypes";
import {
  ProductAttributeTranslation,
  ProductLanguage,
} from "../../../types/product/productTypes";
import { useGetProductById } from "../../../graphql/queries/Products/Products/findProduct";
import { useUpdateProduct } from "../../../graphql/mutations/Product/Product/updateProduct";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useGetAllAttribute } from "../../../graphql/queries/Products/Attributes/getAttribute";
import { useGetAllSpecies } from "../../../graphql/queries/Filter/Species/getSpecies";
import { useGetAllPackages } from "../../../graphql/queries/Filter/Packages/getPackages";
import { useGetAllLayers } from "../../../graphql/queries/Filter/Layer/getLayers";

const EditAttribute = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [mainImage, setMainImage] = useState<string>("");
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const { data: attributeData } = useGetAllAttribute();
  const { data: speciesData } = useGetAllSpecies();
  const { data: packageData } = useGetAllPackages();
  const { data: layersData } = useGetAllLayers();

  const { data } = useGetOneAttribute(id!);
  const { updateAttribute, loading: updating } = useUpdateAttribute(() =>
    navigate("/products")
  );
  const [form] = Form.useForm();
  const [translationMap, setTranslationMap] = useState<
    Record<LanguageCode, ProductAttributeTranslation | null>
  >({
    KA: null,
    EN: null,
    RU: null,
  });
  const [uploadedImage, setUploadedImage] = useState<string>();
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useGetProductById(id);

  const {
    updateProduct,
    loading: updateLoading,
    error: updateError,
  } = useUpdateProduct(() => {
    navigate("/products");
  });

  useEffect(() => {
    if (productData) {
      const map: Record<LanguageCode, ProductAttributeTranslation | null> = {
        EN: null,
        RU: null,
        KA: null,
      };

      productData.translations.forEach((translation: any) => {
        const languageCode = translation.language as LanguageCode;
        map[languageCode] = translation;
      });

      setTranslationMap(map);
      setMainImage(productData.mainImageUrl);
      setUploadedImage(productData.mainImageUrl);
      setAdditionalImages(productData.additionalImages || []);

      const formValues: any = {
        mainImageUrl: productData.mainImageUrl,
        additionalImages: productData.additionalImages,
        attributes: productData.attributes.map((attr: any) => attr.id),
        species: productData.species.map((species: any) => species.id),
        packageSizes: productData.packageSizes.map((pkg: any) => pkg.id),
        layerCounts: productData.layerCounts.map((layer: any) => layer.id),
        translations: productData.translations.reduce(
          (acc: any, translation: any) => {
            acc[translation.language as LanguageCode] = {
              name: translation.name,
              description: translation.description,
            };
            return acc;
          },
          {}
        ),
      };

      form.setFieldsValue(formValues);
    }
  }, [productData, form]);

  const handleUpload = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const newImageUrl = info.file.response.url;
      setUploadedImage(newImageUrl);
      message.success(`${info.file?.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file?.name} file upload failed.`);
    }
  };

  const handleRemoveImage = (imageUrl: string) => {
    setAdditionalImages((prevImages) =>
      prevImages.filter((image) => image !== imageUrl)
    );
  };

  const onFinish = async (values: any) => {
    const attributeData: any = {
      mainImageUrl: { set: mainImage },
      additionalImages: { set: additionalImages },
      attributes: {
        set: values.attributes.map((att: string) => ({ id: att })),
      },
      species: {
        set: values.species.map((spec: string) => ({ id: spec })),
      },
      packageSizes: {
        set: values.packageSizes.map((pkg: string) => ({ id: pkg })),
      },
      layerCounts: {
        set: values.layerCounts.map((layer: string) => ({ id: layer })),
      },
      translations: {
        upsert: languages
          .map((language) => {
            const translation = translationMap[language.code as LanguageCode];
            if (!translation) {
              console.error(
                `No translation found for language code: ${language.code}`
              );
              return null;
            }
            const name = values.translations[language.code]?.name || "";
            const description =
              values.translations[language.code]?.description || "";

            if (!name && !description) return null;
            return {
              where: { id: translation.id },
              update: {
                name: { set: name },
                description: { set: description },
              },
              create: {
                language: language.code as ProductLanguage,
                name: name,
                description: description,
              },
            };
          })
          .filter(Boolean),
      },
    };

    try {
      const { data } = await updateProduct({
        variables: { id: id || "", data: attributeData },
      });

      if (data) {
        message.success("Product updated successfully");
        navigate("/products");
      } else {
        throw new Error("Product update failed");
      }
    } catch (err) {
      message.error(
        "An error occurred: " +
          (err instanceof Error ? err.message : String(err))
      );
    }
  };

  const uploadMainImageProps = {
    beforeUpload: (file: any) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      try {
        const uploadUrl = `${process.env.REACT_APP_API_URL}/upload`;

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        const imageUrl = responseData.url;

        setMainImage(imageUrl);
        form.setFieldsValue({ mainImageUrl: imageUrl });

        onSuccess();
      } catch (error) {
        console.error("Upload error:", error);
        onError(error);
      }
    },
  };

  const uploadAdditionalImagesProps = {
    beforeUpload: (file: any) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      try {
        const uploadUrl = `${process.env.REACT_APP_API_URL}/upload`;

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        const imageUrl = responseData.url;

        setAdditionalImages((prevImages) => [...prevImages, imageUrl]);

        onSuccess();
      } catch (error) {
        console.error("Upload error:", error);
        onError(error);
      }
    },
  };

  return (
    <Fragment>
      <Card title="პროდუქტის ატრიბუტების რედაქტირება">
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="მთავარი სურათი"
            name="mainImageUrl"
            rules={[
              {
                message: "მთავარი სურათის ატვირთვა სავალდებულოა",
              },
            ]}
          >
            <Upload {...uploadMainImageProps} showUploadList={false}>
              <Button icon={<UploadOutlined />}>ატვირთეთ მთავარი სურათი</Button>
            </Upload>
            {mainImage && (
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/${mainImage}`}
                alt="Main"
                style={{
                  width: 120,
                  height: 120,
                  marginTop: 10,
                  objectFit: "cover",
                  borderRadius: 8,
                  border: "1px solid #d9d9d9",
                }}
              />
            )}
          </Form.Item>

          <Form.Item
            label="დამატებითი სურათები"
            name="additionalImages"
            valuePropName="fileList"
          >
            <Upload
              {...uploadAdditionalImagesProps}
              showUploadList={false}
              multiple
            >
              <Button icon={<UploadOutlined />}>
                ატვირთეთ დამატებითი სურათები
              </Button>
            </Upload>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {additionalImages.map((imageUrl) => (
                <div
                  key={imageUrl}
                  style={{
                    position: "relative",
                    width: "120px",
                    height: "120px",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${imageUrl}`}
                    alt="Additional"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                      border: "1px solid #d9d9d9",
                    }}
                  />
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveImage(imageUrl)}
                    style={{
                      position: "absolute",
                      top: "-8px",
                      right: "-8px",
                      background: "#ff4d4f",
                      color: "#fff",
                      borderRadius: "50%",
                      border: "none",
                    }}
                  />
                </div>
              ))}
            </div>
          </Form.Item>

          {languages.map((language) => (
            <Fragment key={language.code}>
              <Divider>{language?.name}</Divider>
              <Form.Item
                label="დასახელება"
                name={["translations", language.code, "name"]}
                rules={[
                  {
                    required: true,
                    message: "დასახელება სავალდებულოა",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="აღწერა"
                name={["translations", language.code, "description"]}
              >
                <Input.TextArea />
              </Form.Item>
            </Fragment>
          ))}

          <Form.Item label="ატრიბუტები" name="attributes">
            <Select
              mode="multiple"
              placeholder="აირჩიე ატრიბუტები"
              style={{ width: "100%" }}
            >
              {attributeData.map((attribute: any) => (
                <Select.Option key={attribute.id} value={attribute.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/${attribute.imageUrl}`}
                      alt={attribute?.name}
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    <p>{attribute.translations[0]?.name}</p>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="სახეობები"
            name="species"
            rules={[{ required: true, message: "გთხოვთ აირჩიოთ სახეობები" }]}
          >
            <Select
              mode="multiple"
              placeholder="აირჩიე სახეობები"
              style={{ width: "100%" }}
            >
              {speciesData.map((species: any) => (
                <Select.Option key={species.id} value={species.id}>
                  <p>{species.translations[0].name}</p>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="რაოდენობა შეკვრაში" name="packageSizes">
            <Select
              mode="multiple"
              placeholder="აირჩიე რაოდენობა შეკვრაში"
              style={{ width: "100%" }}
            >
              {packageData.map((packageSize: any) => (
                <Select.Option key={packageSize.id} value={packageSize.id}>
                  <p>{packageSize.translations[0].name}</p>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="ფენების რაოდენობა" name="layerCounts">
            <Select
              mode="multiple"
              placeholder="აირჩიე ფენების რაოდენობა"
              style={{ width: "100%" }}
            >
              {layersData.map((layerCount: any) => (
                <Select.Option key={layerCount.id} value={layerCount.id}>
                  <p>{layerCount.translations[0].name}</p>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={updating}>
              განახლება
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Fragment>
  );
};

export default EditAttribute;
