import { useEffect } from "react";
import { Button, Flex, Form, Input, message } from "antd";
import { axiosInstance } from "../../utils/axiosConfig";
import styled from "styled-components";
import { ILoginFormValues, ILoginResponseError } from "../login/Login.types";
import { useNavigate, useParams } from "react-router-dom";

const EditAccount = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const { data } = await axiosInstance.get(`/users/${id}`);

        form.setFieldsValue({ email: data.users.email });
      } catch (error) {
        message.error("ანგარიშის ინფორმაციის მიღება ვერ მოხერხდა");
      }
    };

    fetchAccountDetails();
  }, [id, form]);

  const onFinish = async (values: ILoginFormValues) => {
    try {
      const { data } = await axiosInstance.put(`/users/${id}`, values);

      if (!data.success) {
        return message.error("ანგარიშის განახლება ვერ მოხერხდა");
      }
      navigation("/accounts");
      message.success(data.message);
    } catch (error) {
      message.error(
        (error as ILoginResponseError).response?.data?.message ||
          "ანგარიშის განახლება ვერ მოხერხდა"
      );
    }
  };

  return (
    <Main>
      <Flex justify="center" align="center" className="flex">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="ელ.ფოსტა"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "შეავსეთ ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ახალი პაროლი"
            name="password"
            rules={[
              {
                required: true,
                min: 6,
                message: "მინიმუმ 6 ასო",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            ანგარიშის განახლება
          </Button>
        </Form>
      </Flex>
    </Main>
  );
};

const Main = styled.main`
  .flex {
    height: 100vh;
    padding: 0 10px;
  }
  button {
    width: 100%;
  }
  form {
    width: 100%;
    max-width: 400px;
  }
`;

export default EditAccount;
