import { useState, useEffect } from "react";
import { useCreateGalleryVideo } from "../../../graphql/mutations/Gallery/Gallery-video/createVideo";
import { Button, Upload, message, Typography, Space, Input } from "antd";
import { useNavigate } from "react-router-dom";
import {
  UploadOutlined,
  VideoCameraOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import { useGetAllGalleryVideos } from "../../../graphql/queries/gallery/Gallery-video/getVideo";

const { Title } = Typography;

const CreateVideo = () => {
  const navigate = useNavigate();
  const [videoFile, setVideoFile] = useState<RcFile | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<RcFile | null>(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState<string | null>(null);
  const [thumbnailPreviewUrl, setThumbnailPreviewUrl] = useState<string | null>(
    null
  );

  const { refetch } = useGetAllGalleryVideos();
  const { createGalleryVideo, loading, error, data } = useCreateGalleryVideo(
    () => {
      navigate("/gallery");
    }
  );

  useEffect(() => {
    const fetchVideos = async () => {
      const result = await refetch();
      const videos = result.data?.galleryVideos || [];
    };

    fetchVideos();
  }, [refetch]);

  const handleVideoChange = (info: any) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj as RcFile;
      setVideoFile(file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const handleThumbnailChange = (info: any) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj as RcFile;
      setThumbnailFile(file);
      const thumbnailUrl = URL.createObjectURL(file);
      setThumbnailPreviewUrl(thumbnailUrl);
    }
  };

  const handleSubmit = async () => {
    if (!videoFile || !thumbnailFile) {
      message.error("გთხოვთ ატვირთოთ ვიდეო და სურათი.");
      return;
    }

    try {
      const videoUrl = await uploadFile(videoFile);
      const thumbnailUrl = await uploadFile(thumbnailFile);

      await createGalleryVideo({
        variables: {
          data: {
            url: videoUrl,
            thumbnailUrl: thumbnailUrl,
          },
        },
      });

      refetch();
      message.success("ვიდეო აიტვირთა წარმატებით!");
    } catch (err) {
      console.error("Error uploading files or creating gallery video:", err);
      message.error("ვიდეო ან სურათი არ აიტვირთა");
    }
  };

  const uploadFile = async (file: RcFile): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }

      const result = await response.json();
      return result.url;
    } catch (err) {
      console.error("Error uploading file:", err);
      throw err;
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Title level={3}>ატვირთე ვიდეო და სურათი</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Upload
          customRequest={({ file, onSuccess }: any) => {
            onSuccess?.({}, file);
          }}
          showUploadList={false}
          accept="video/*"
          onChange={handleVideoChange}
        >
          <Button icon={<VideoCameraOutlined />} style={{ width: "100%" }}>
            აირჩიე ვიდეო
          </Button>
        </Upload>
        {videoPreviewUrl && (
          <div>
            <video width="300px" controls>
              <source src={videoPreviewUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <Upload
          customRequest={({ file, onSuccess }: any) => {
            onSuccess?.({}, file);
          }}
          showUploadList={false}
          accept="image/*"
          onChange={handleThumbnailChange}
        >
          <Button icon={<PictureOutlined />} style={{ width: "100%" }}>
            აირჩიე სურათი
          </Button>
        </Upload>
        {thumbnailPreviewUrl && (
          <div>
            <img
              src={thumbnailPreviewUrl}
              alt="Thumbnail Preview"
              style={{ width: "300px", height: "300px" }}
            />
          </div>
        )}
        <Button
          type="primary"
          icon={<UploadOutlined />}
          onClick={handleSubmit}
          loading={loading}
          style={{ width: "100%" }}
        >
          ატვირთვა
        </Button>
      </Space>
      {error && (
        <Typography.Text
          type="danger"
          style={{ display: "block", marginTop: "10px" }}
        >
          Error: {error.message}
        </Typography.Text>
      )}
      {data && (
        <Typography.Text
          type="success"
          style={{ display: "block", marginTop: "10px" }}
        >
          ვიდეო აიტვირთა წარმატებით!
        </Typography.Text>
      )}
    </div>
  );
};

export default CreateVideo;
