import { gql } from "@apollo/client";

const UPDATE_HERO = gql`
  mutation UpdateHero($id: String, $data: HeroUpdateInput!) {
    updateOneHero(where: { id: $id }, data: $data) {
      id
      name {
        text
        language
      }
      description {
        text
        language
      }
      features {
        titles {
          text
          language
        }
        descriptions {
          text
          language
        }
      }
    }
  }
`;

export default UPDATE_HERO;
