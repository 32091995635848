import { Button, message, Popconfirm, Table } from "antd";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { useGetAllLayers } from "../../../../graphql/queries/Filter/Layer/getLayers";
import { useDeleteLayer } from "../../../../graphql/mutations/Filter/Layer/deleteLayer";
import { ApolloError } from "@apollo/client";

const Layers = () => {
  const { data, refetch } = useGetAllLayers();
  const { handleDeleteLayer } = useDeleteLayer(refetch);

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title: "სათაური",
          key: "packages title",
          render: (_, record: any) => (
            <div className="flex flex-col gap-2">
              <p>{record.translations[0].name}</p>
            </div>
          ),
        },
        {
          title: "ქმედება",
          key: "packages action",
          render: (_: any, record: any) => (
            <Button.Group>
              <Link to={`/layers/edit/${record.id}`}>
                <Button type="primary">რედაქტირება</Button>
              </Link>
              <Popconfirm
                title="გსურთ წაშლა?"
                onConfirm={async () => {
                  const { success, error } = await handleDeleteLayer(record.id);
                  if (success) {
                    message.success("სიახლე წაიშალა");
                  } else {
                    if (error instanceof ApolloError) {
                      message.error(`შეცდომა წაშლისას: ${error.message}`);
                    } else {
                      message.error("შეცდომა წაშლისას: უცნობი შეცდომა");
                    }
                  }
                }}
                okText="კი"
                cancelText="არა"
              >
                <Button type="primary" danger>
                  წაშლა
                </Button>
              </Popconfirm>
            </Button.Group>
          ),
        },
      ]}
      dataSource={data}
      title={() => (
        <div>
          <div className="BlogTitle-addButton">
            <Title level={3}>ფენების რაოდებონა</Title>
            <Link to={"/layers/create"}>
              <Button type="primary">დამატება</Button>
            </Link>
          </div>
        </div>
      )}
      tableLayout="fixed"
      bordered
    />
  );
};

export default Layers;
