import { gql, useMutation } from "@apollo/client";
import {
  UpdateAttributeData,
  UpdateAttributeInput,
  UpdateAttributeVars,
} from "../../../../types/product/productTypes";

export const UPDATE_ATTRIBUTE = gql`
  mutation UpdateAttribute($id: String!, $data: ProductAttributeUpdateInput!) {
    updateOneProductAttribute(where: { id: $id }, data: $data) {
      id
      imageUrl
      translations {
        id
        language
        name
      }
    }
  }
`;

export const useUpdateAttribute = (refetchAboutUs: () => void) => {
  const [updateAttribute, { loading, error, data }] = useMutation<
    any,
    { id: string; data: any }
  >(UPDATE_ATTRIBUTE, {
    onCompleted: () => {
      refetchAboutUs();
    },
  });

  return { updateAttribute, loading, error, data };
};
