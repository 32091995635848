import { useState } from "react";
import { Form, Input, Button, Upload, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import { useNavigate } from "react-router-dom";
import { useCreateGalleryImage } from "../../../graphql/mutations/Gallery/Gallery-image/createImage";
import { useGetAllGalleryImages } from "../../../graphql/queries/gallery/Gallery-image/getImage";

const { Item: FormItem } = Form;

const CreateImage = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { refetch } = useGetAllGalleryImages();
  const { createGalleryImage, loading } = useCreateGalleryImage(() => {
    navigate("/gallery");
  });
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    const { mobileIndex, desktopIndex } = values;

    if (fileList.length === 0) {
      console.error("No image file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj as Blob);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (response.ok) {
      createGalleryImage({
        variables: {
          data: {
            url: data.url,
            mobileIndex: parseInt(mobileIndex),
            desktopIndex: parseInt(desktopIndex),
          },
        },
      });
      refetch();
    } else {
      console.error("Image upload failed:", data.message);
    }
  };

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <h2>სურათის დამატება</h2>
      {loading && <Spin />}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          name="image"
          label="ატვირთეთ სურათი"
          rules={[{ required: true, message: "გთხოვთ ატვირთოთ სურათი" }]}
        >
          <Upload
            fileList={fileList}
            beforeUpload={() => false}
            onChange={handleFileChange}
            listType="picture-card"
            accept="image/*"
          >
            {fileList.length >= 1 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>ატვირთვა</div>
              </div>
            )}
          </Upload>
        </FormItem>

        <FormItem
          name="mobileIndex"
          label="ტელეფონის ინდექსი"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ ტელეფონის ინდექსი" },
          ]}
        >
          <Input type="number" min={0} />
        </FormItem>

        <FormItem
          name="desktopIndex"
          label="დექსტოპის ინდექსი"
          rules={[
            { required: true, message: "გთხოვთ შეიყვანოთ დექსტოპის ინდექსი" },
          ]}
        >
          <Input type="number" min={0} />
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit" loading={loading}>
            დამატება
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default CreateImage;
