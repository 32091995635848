import React, { useCallback, useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  Breadcrumb,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Upload,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import languages from "../../../constants/languages";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  ComponentDataUpdates,
  FormValues,
} from "../../../types/blog/blogTypes";
import { useUpdateContent } from "../../../graphql/mutations/Contents/updateContent";
import { useGetContentById } from "../../../graphql/queries/Contents/findContent";

const EditContent: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [componentType, setComponentType] = useState<"editor" | "image">(
    "editor"
  );
  const [components, setComponents] = useState<ComponentDataUpdates[]>([]);

  const {
    data,
    loading: queryLoading,
    error,
    refetch,
  } = useGetContentById(id!);
  const { updateContent, loading: updateLoading } = useUpdateContent(refetch);

  useEffect(() => {
    if (data) {
      const post = data;
      form.setFieldsValue({
        index: post.index,
        ...post.titles?.reduce((acc: any, title: any) => {
          acc[`title-${title.language}`] = title.title;
          return acc;
        }, {}),
      });

      setComponents(
        post.components?.map((component: any, index: number) => ({
          type: component.type === "TRANSLATION" ? "editor" : "image",
          key: `existing-${index}-${Date.now()}`,
          data: component,
        })) || []
      );
    }
  }, [data, form]);

  const handleUpload = useCallback(
    (info: any, fieldName: string) => {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        const currentUrls = form.getFieldValue(fieldName) || [];
        form.setFieldsValue({ [fieldName]: currentUrls });
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    [form]
  );

  const normFile = useCallback((e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }, []);

  const addComponent = useCallback(() => {
    setComponents((prevComponents) => [
      ...prevComponents,
      { type: componentType, key: `new-${Date.now()}`, data: {} },
    ]);
  }, [componentType]);

  const onFinish = useCallback(
    async (values: FormValues) => {
      try {
        const contentData: any = {};

        if (Number(values.index) !== data.index) {
          contentData.index = { set: Number(values.index) };
        }

        const updatedTitles = data.titles.filter(
          (title: { id: string; language: string; title: string }) =>
            values[`title-${title.language}`] !== title.title
        );
        if (updatedTitles.length > 0) {
          contentData.titles = {
            updateMany: updatedTitles.map(
              (title: { id: string; language: string }) => ({
                where: { id: { equals: title.id } },
                data: { title: { set: values[`title-${title.language}`] } },
              })
            ),
          };
        }

        if (
          values.mainImage?.[0]?.response?.url &&
          values.mainImage[0].response.url !== data.imageUrl
        ) {
          contentData.imageUrl = { set: values.mainImage[0].response.url };
        }

        const updatedComponents = components
          .map((component: ComponentDataUpdates) => {
            if (component.key.startsWith("new-")) {
              // Handle new components
              if (component.type === "image") {
                const imageList =
                  values[`images-${component.key}`]?.fileList || [];
                return {
                  create: {
                    type: "IMAGE",
                    images: {
                      create: imageList.map((file: any) => ({
                        url: file.response?.url || file.url,
                      })),
                    },
                  },
                };
              } else {
                return {
                  create: {
                    type: "TRANSLATION",
                    translations: {
                      create: languages.map((language) => ({
                        language: language.code,
                        subtitle:
                          values[`subtitle-${component.key}-${language.code}`],
                        contentText:
                          values[
                            `contentText-${component.key}-${language.code}`
                          ],
                      })),
                    },
                  },
                };
              }
            } else {
              // Handle existing components
              if (component.type === "image") {
                const imageList =
                  values[`images-${component.key}`]?.fileList || [];
                const { id } = component.data?.images as any;

                return {
                  where: { id: component.data?.id },
                  data: {
                    images: {
                      updateMany: imageList.map((file: any) => ({
                        where: { id: { equals: id } },
                        data: {
                          url: { set: file.response?.url || file.url },
                        },
                      })),
                    },
                  },
                };
              } else {
                const updatedTranslations = languages.filter(
                  (language) =>
                    values[`subtitle-${component.key}-${language.code}`] !==
                      component.data?.translations?.find(
                        (t: any) => t.language === language.code
                      )?.subtitle ||
                    values[`contentText-${component.key}-${language.code}`] !==
                      component.data?.translations?.find(
                        (t: any) => t.language === language.code
                      )?.contentText
                );
                if (updatedTranslations.length > 0) {
                  return {
                    where: { id: component.data?.id },
                    data: {
                      translations: {
                        updateMany: updatedTranslations.map((language) => ({
                          where: { language: { equals: language.code } },
                          data: {
                            subtitle: {
                              set: values[
                                `subtitle-${component.key}-${language.code}`
                              ],
                            },
                            contentText: {
                              set: values[
                                `contentText-${component.key}-${language.code}`
                              ],
                            },
                          },
                        })),
                      },
                    },
                  };
                }
              }
            }
            return null;
          })
          .filter(Boolean);

        if (updatedComponents.length > 0) {
          contentData.components = {
            update: updatedComponents.filter((c: any) => c.where),
            create: updatedComponents
              .filter((c: any) => c.create)
              .map((c: any) => c.create),
          };
        }

        if (Object.keys(contentData).length > 0) {
          const { data: updateData } = await updateContent({
            variables: { id: id!, data: contentData },
          });
          if (updateData) {
            message.success("ბლოგი განახლებულია");
            navigate("/blogs");
          } else {
            throw new Error("Content update failed");
          }
        } else {
          message.info("No changes detected");
        }
      } catch (err) {
        console.error("Error details:", err);
        if (err instanceof ApolloError) {
          console.error("GraphQL errors:", err.graphQLErrors);
          console.error("Network error:", err.networkError);
        }
        message.error(
          "შეცდომა მოხდა: " + (err instanceof Error ? err.message : String(err))
        );
      }
    },
    [components, data, id, navigate, updateContent]
  );

  if (queryLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="flex flex-col gap-2 w-full">
      <Breadcrumb
        items={[{ title: "ბლოგი", href: "/blogs" }, { title: "რედაქტირება" }]}
      />
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        name="edit_content_form"
        className="max-w-[1000px] w-full"
        autoComplete="off"
        layout="vertical"
        labelWrap
        onFinish={onFinish}
      >
        <Card size="small">
          <Form.Item
            label="ინდექსი"
            name="index"
            rules={[{ required: true, message: "ინდექსი აუცილებელია!" }]}
          >
            <Input placeholder="ინდექსი" />
          </Form.Item>

          <Form.Item
            name="mainImage"
            label="მთავარი სურათი"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={(info) => handleUpload(info, "mainImage")}
              maxCount={1}
              defaultFileList={
                data?.imageUrl
                  ? [
                      {
                        uid: "-1",
                        name: "image.png",
                        status: "done",
                        url: `${process.env.REACT_APP_API_URL}/uploads/${data.imageUrl}`,
                      },
                    ]
                  : []
              }
            >
              <Button icon={<UploadOutlined />}>ატვირთეთ მთავარი სურათი</Button>
            </Upload>
          </Form.Item>

          {languages.map((language) => (
            <Form.Item
              key={language.code}
              label={`სათაური (${language.name})`}
              name={`title-${language.code}`}
              rules={[
                {
                  required: true,
                  message: `${language.name} სათაური აუცილებელია!`,
                },
              ]}
            >
              <Input placeholder={`სათაური (${language.name})`} />
            </Form.Item>
          ))}

          {components.map((component: ComponentDataUpdates) => (
            <React.Fragment key={component.key}>
              {component.type === "image" ? (
                <Form.Item name={`images-${component.key}`} label="სურათი">
                  <Upload
                    action={`${process.env.REACT_APP_API_URL}/upload`}
                    listType="picture"
                    onChange={(info) => handleUpload(info, component.key)}
                    maxCount={1}
                    defaultFileList={
                      component.data?.images?.map(
                        (image: any, index: number) => ({
                          uid: `-${index}`,
                          name: `image-${index}.png`,
                          status: "done",
                          url: `${process.env.REACT_APP_API_URL}/uploads/${image.url}`,
                        })
                      ) || []
                    }
                  >
                    <Button icon={<UploadOutlined />}>ატვირთეთ სათაური</Button>
                  </Upload>
                </Form.Item>
              ) : (
                languages.map((language) => (
                  <React.Fragment key={language.code}>
                    <Divider orientation="center">{language.name}</Divider>
                    <Form.Item
                      label="ქვესათაური"
                      name={`subtitle-${component.key}-${language.code}`}
                      initialValue={
                        component.data?.translations?.find(
                          (t: any) => t.language === language.code
                        )?.subtitle
                      }
                      rules={[
                        {
                          required: true,
                          message: `${language.code} ქვესათაური აუცილებელია!`,
                        },
                      ]}
                    >
                      <Input placeholder="ქვესათაური" />
                    </Form.Item>
                    <Form.Item
                      label="ტექსტი"
                      name={`contentText-${component.key}-${language.code}`}
                      initialValue={
                        component.data?.translations?.find(
                          (t: any) => t.language === language.code
                        )?.contentText
                      }
                      rules={[
                        {
                          required: true,
                          message: `${language.code} ტექსტი აუცილებელია!`,
                        },
                      ]}
                    >
                      <ReactQuill theme="snow" />
                    </Form.Item>
                  </React.Fragment>
                ))
              )}
            </React.Fragment>
          ))}

          <Form.Item label="კომპონენტის ტიპი">
            <Radio.Group
              onChange={(e) => setComponentType(e.target.value)}
              value={componentType}
            >
              <Radio value="editor">ედიტორი</Radio>
              <Radio value="image">სურათი</Radio>
            </Radio.Group>
          </Form.Item>

          <Button
            type="dashed"
            onClick={addComponent}
            block
            icon={<PlusOutlined />}
          >
            კომპონენტის დამატება
          </Button>
        </Card>
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            განახლება
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditContent;
