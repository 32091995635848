import { Button, Flex, Form, Input, message } from "antd";
import userStore from "../../store/userStore";
import { axiosInstance } from "../../utils/axiosConfig";
import { ILoginFormValues, ILoginResponseError } from "./Login.types";
import styled from "styled-components";

export const Login = () => {
  const [form] = Form.useForm();
  const { login } = userStore();

  const onFinish = async (values: ILoginFormValues) => {
    try {
      const { data } = await axiosInstance.post("/admin-auth/login", values);
      if (!data.accessToken || !data.user) {
        return message.error("შეცდომა!");
      }

      login(data.accessToken, data.user);
    } catch (error) {
      message.error((error as ILoginResponseError).response.data.message);
    }
  };

  return (
    <Main>
      <Flex justify="center" align="center" className="flex">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="ელ.ფოსტა"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "შეავსეთ ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="პაროლი"
            name="password"
            rules={[{ required: true, message: "შეავსეთ ველი!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            შესვლა
          </Button>
        </Form>
      </Flex>
    </Main>
  );
};

const Main = styled.main`
  .flex {
    height: 100vh;
    padding: 0 10px;
  }

  button {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 400px;
  }
`;
