import { gql, useQuery } from "@apollo/client";

const GET_ALL_WHOWEARE = gql`
  query MyQuery {
    whoWeAres {
      id
      imageUrl
      videoUrl
      enDescription
      kaDescription
      ruDescription
      productAttributes {
        id
        translations {
          language
          name
        }
      }
    }
  }
`;

export const useGetAllWhoWeAre = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_WHOWEARE);

  return {
    data: data?.whoWeAres || [],
    loading,
    error,
    refetch,
  };
};
