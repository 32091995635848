import { ApolloError } from "@apollo/client";
import { Button, message, Popconfirm, Table } from "antd";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { useGetAllWhoWeAre } from "../../../graphql/queries/Landing/WhoWeAre/getWoWeAre";

const WhoWeAre = () => {
  const { data } = useGetAllWhoWeAre();

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title: "სურათი",
          key: "who-we-are image",
          render: (_, record: any) => (
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {record.imageUrl ? (
                <img
                  src={record.imageUrl}
                  alt="Image"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.currentTarget.src = "/path/to/fallback/image.jpg";
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Image
                </div>
              )}
            </div>
          ),
        },
        {
          title: "სათაური",
          key: "who-we-are title",
          render: (_, record: any) => (
            <div className="flex flex-col gap-2">
              <p>{record.kaDescription}</p>
            </div>
          ),
        },
        {
          title: "ქმედება",
          key: "who-we-are action",
          render: (_: any, record: any) => (
            <Button.Group>
              <Link to={`/landing/who-we-are/edit/${record.id}`}>
                <Button type="primary">რედაქტირება</Button>
              </Link>
            </Button.Group>
          ),
        },
      ]}
      dataSource={data}
      title={() => (
        <div>
          <div className="BlogTitle-addButton">
            <Title level={3}>ვინ ვართ ჩვენ</Title>
            {data.length === 0 && (
              <Link to={"/landing/who-we-are/create"}>
                <Button type="primary">დამატება</Button>
              </Link>
            )}
          </div>
        </div>
      )}
      tableLayout="fixed"
      bordered
    />
  );
};

export default WhoWeAre;
