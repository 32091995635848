import { gql, useQuery } from "@apollo/client";

export const FIND_GALLERY_VIDEO = gql`
  query GetGalleryVideo($id: String!) {
    galleryVideo(where: { id: $id }) {
      id
      url
      thumbnailUrl
      createdAt
      updatedAt
    }
  }
`;

export const useGetOneGalleryVideo = (id: string) => {
  const { data, loading, error } = useQuery(FIND_GALLERY_VIDEO, {
    variables: { id },
    skip: !id,
  });

  return {
    data,
    loading,
    error,
  };
};
