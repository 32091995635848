import { Button, Table } from "antd";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import { useGetAllOrders } from "../../../graphql/queries/Landing/Order/getOrders";

const Order = () => {
  const { data } = useGetAllOrders();

  return (
    <Table
      rowKey="id"
      columns={[
        {
          title: "სათაური",
          key: "order title",
          render: (_, record: any) => (
            <div className="flex flex-col gap-2">
              <p>{record.kaName}</p>
            </div>
          ),
        },
        {
          title: "ქმედება",
          key: "order action",
          render: (_: any, record: any) => (
            <Button.Group>
              <Link to={`/landing/order/edit/${record.id}`}>
                <Button type="primary">რედაქტირება</Button>
              </Link>
            </Button.Group>
          ),
        },
      ]}
      dataSource={data}
      title={() => (
        <div>
          <div className="BlogTitle-addButton">
            <Title level={3}>შეუკვეთე</Title>
            {data.length === 0 && (
              <Link to={"/landing/order/create"}>
                <Button type="primary">დამატება</Button>
              </Link>
            )}
          </div>
        </div>
      )}
      tableLayout="fixed"
      bordered
    />
  );
};

export default Order;
