import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const CREATE_ONE_LAYER = gql`
  mutation CreateOneLayerCount($data: LayerCountCreateInput!) {
    createOneLayerCount(data: $data) {
      id
      translations {
        language
        name
      }
    }
  }
`;

export const useCreateLayer = (refetchAttributes: () => void) => {
  const [createLayer, { loading, error, data }] = useMutation<{ data: any }>(
    CREATE_ONE_LAYER,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { createLayer, loading, error, data };
};
