import ImageList from "./Gallery-image/ImageList";
import VideoList from "./Gallery-video/VideoList";

const Gallery = () => {
  return (
    <div>
      <VideoList />
      <ImageList />
    </div>
  );
};

export default Gallery;
