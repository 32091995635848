import { gql, useQuery } from "@apollo/client";
import { GetAllGalleryVideosResponse } from "../../../../types/gallery/videoTypes";

const GET_ALL_GALLERY_VIDEOS = gql`
  query GetAllGalleryVideos {
    galleryVideos {
      id
      url
      thumbnailUrl
      createdAt
      updatedAt
    }
  }
`;

export const useGetAllGalleryVideos = () => {
  const { data, loading, error, refetch } =
    useQuery<GetAllGalleryVideosResponse>(GET_ALL_GALLERY_VIDEOS);

  return {
    data: data?.galleryVideos || [],
    loading,
    error,
    refetch,
  };
};
