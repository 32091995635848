import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export const UPDATE_ONE_SPECIE = gql`
  mutation UpdateSpecies(
    $data: SpeciesUpdateInput!
    $where: SpeciesWhereUniqueInput!
  ) {
    updateOneSpecies(data: $data, where: $where) {
      translations {
        name
        language
      }
    }
  }
`;

export const useUpdateSpecie = (refetchAttributes: () => void) => {
  const [updateSpecies, { loading, error, data }] = useMutation<{ data: any }>(
    UPDATE_ONE_SPECIE,
    {
      onCompleted: () => {
        refetchAttributes();
      },
    }
  );

  return { updateSpecies, loading, error, data };
};
