import { Button, Layout, Menu } from "antd";
import styled from "styled-components";
import {
  AppstoreOutlined,
  FileTextOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import userStore from "../store/userStore";

const { Sider } = Layout;
const { SubMenu } = Menu;

const items = [
  {
    icon: <FileTextOutlined />,
    label: "ბლოგი",
    href: "/blogs",
  },
  {
    icon: <FileTextOutlined />,
    label: "კომპანიის შესახებ",
    href: "/about-us",
  },
  {
    icon: <FileTextOutlined />,
    label: "გალერია",
    href: "/gallery",
  },
];

const SideBar = ({
  onCollapse,
  collapsed,
}: {
  onCollapse: (collapsed: boolean) => void;
  collapsed: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { logout } = userStore();
  const user = localStorage.getItem("user");
  const role = JSON.parse(user!).isAdmin;

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed} width={250}>
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={["/landing"]}
      >
        {/* Add SubMenu for პროდუქტები (product) */}
        <SubMenu key="product" icon={<AppstoreOutlined />} title="პროდუქცია">
          <Menu.Item key="/attributes" onClick={() => navigate("/attributes")}>
            ატრიბუტები
          </Menu.Item>
          <Menu.Item key="/species" onClick={() => navigate("/species")}>
            სახეობა
          </Menu.Item>
          <Menu.Item key="/packages" onClick={() => navigate("/packages")}>
            რაოდენობა შეკვრაში
          </Menu.Item>
          <Menu.Item key="/layers" onClick={() => navigate("/layers")}>
            ფენების რაოდენობა
          </Menu.Item>
          <Menu.Item key="/products" onClick={() => navigate("/products")}>
            პროდუქტი
          </Menu.Item>
        </SubMenu>

        {items.map((item) => (
          <Menu.Item
            key={item.href}
            icon={item.icon}
            onClick={() => navigate(item.href)}
          >
            {item.label}
          </Menu.Item>
        ))}

        {/* Add SubMenu for ლენდინგი (Landing) */}
        <SubMenu key="landing" icon={<FileTextOutlined />} title="ლენდინგი">
          <Menu.Item key="/hero" onClick={() => navigate("/hero")}>
            შესავალი
          </Menu.Item>
          <Menu.Item
            key="/landing/section1"
            onClick={() => navigate("/landing/order")}
          >
            შეუკვეთე
          </Menu.Item>
          <Menu.Item
            key="/landing/section2"
            onClick={() => navigate("/landing/who-we-are")}
          >
            ვინ ვართ ჩვენ
          </Menu.Item>
        </SubMenu>

        {role && (
          <Menu.Item
            icon={<PlusOutlined />}
            title="დაამატე ექაუნთი"
            key="/accounts"
            onClick={() => navigate("/accounts")}
          >
            დაამატე ექაუნთი
          </Menu.Item>
        )}
      </Menu>

      <Bottom>
        <Button
          danger
          type="primary"
          onClick={() => logout()}
          style={{ width: "100%", marginTop: 10, borderRadius: 0 }}
        >
          გასვლა
        </Button>
        <Arrow
          className="trigger"
          onClick={() => onCollapse(!collapsed)}
          style={{ color: "#fff" }}
          $collapsed={collapsed}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="arrowRight$">
              <g id="Icon">
                <path
                  d="M7.21415 12.0502L11.4572 16.2932C11.8476 16.6836 11.8476 17.3167 11.4572 17.7072C11.0667 18.0976 10.4336 18.0976 10.0432 17.7072L5.09326 12.7573C4.70273 12.3667 4.70273 11.7336 5.09326 11.343L10.0432 6.39315C10.4336 6.00268 11.0667 6.00268 11.4572 6.39315C11.8476 6.78362 11.8476 7.41668 11.4572 7.80715L7.21415 12.0502Z"
                  fill="#1A1A1A"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 12C20 12.5523 19.5523 13 19 13H6.5C5.94772 13 5.5 12.5523 5.5 12C5.5 11.4477 5.94772 11 6.5 11H19C19.5523 11 20 11.4477 20 12Z"
                  fill="#1A1A1A"
                />
              </g>
            </g>
          </svg>
        </Arrow>
      </Bottom>
    </StyledSider>
  );
};

export default SideBar;

const StyledSider = styled(Sider)<{ collapsed: boolean }>`
  text-align: center;
  line-height: 120px;
  color: #fff;
  height: 100dvh;
  position: fixed;
  left: 0;
  bottom: 0;
  border: 1px solid #e4e4e7;

  & > .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.collapsed ? "center" : "flex-start")};

    & > .ant-menu {
      width: 100% !important;
    }
  }

  & .ant-menu-title-content {
    display: flex;
  }
`;

const Arrow = styled.div<{ $collapsed: boolean }>`
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid rgba(26, 26, 26, 0.25);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  user-select: none;

  img {
    transform: ${(props) =>
      props.$collapsed ? "rotate(180deg)" : "rotate(0)"};
    transition: all 0.3s ease-in-out;
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
`;
